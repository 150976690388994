var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "template-layout",
          staticStyle: { "min-height": "1134px", width: "1380px" }
        },
        [
          _c("div", { staticClass: "template-layout-container" }, [
            _c("div", { staticClass: "template-layout-content" }, [
              _c("h2", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-template" }),
                _vm._v(_vm._s(_vm.$t("Edit Template")) + " ")
              ]),
              _c("div", {
                staticClass: "unit-select",
                staticStyle: { height: "38px" }
              }),
              _c("div", { staticClass: "template-grid" }, [
                _c(
                  "div",
                  { staticClass: "tools" },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "btn-white",
                                            attrs: { icon: "" },
                                            on: { click: _vm.addText }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/icon-format-text-variant.png")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("Add Text")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "btn-white",
                                            on: { click: _vm.addProduct }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/icon-alert-circle.png")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("Add Product")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "btn-white",
                                            on: { click: _vm.addEllipse }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/icon-circle-outline.png")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("Add Circle")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "btn-white",
                                            on: { click: _vm.addRect }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/icon-checkbox-blank.png")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("Add Rectangle")))
                            ])
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "btn-white",
                                            on: { click: _vm.addTriangle }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/icon-triangle.png")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("Add Triangle")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "btn-white",
                                            on: { click: _vm.addLine }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/icon-slash-forward.png")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("Add Line")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "btn-white",
                                            on: { click: _vm.getFile }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/icon-file-image.png")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("Add Image")))])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "btn-white",
                                            on: { click: _vm.getTemplate }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/icon-view-quilt.png")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("Import Template")))
                            ])
                          ]
                        ),
                        _c("v-file-input", {
                          ref: "add_image",
                          staticStyle: { display: "none" },
                          attrs: { accept: ".jpg, .png" },
                          on: { change: _vm.changeFile },
                          model: {
                            value: _vm.inputFile,
                            callback: function($$v) {
                              _vm.inputFile = $$v
                            },
                            expression: "inputFile"
                          }
                        }),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "btn-white",
                                            staticStyle: { width: "88px" },
                                            on: { click: _vm.delShapes }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/icon-trash-can.png")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("Delete Shape")))])]
                        )
                      ],
                      1
                    ),
                    _c("v-divider")
                  ],
                  1
                ),
                _c(
                  "div",
                  { ref: "templateCanvas", staticClass: "template-canvas" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "yAxis",
                        staticStyle: {
                          "padding-top": "10px",
                          "box-sizing": "border-box",
                          display: "inline-block"
                        }
                      },
                      _vm._l(_vm.ruler.item, function(item) {
                        return _c("span", {
                          key: item.pixel,
                          class: {
                            graduation: item.isGraduation,
                            big: item.isBig
                          },
                          style: { top: item.pixel + "px" }
                        })
                      }),
                      0
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "xAxis",
                        staticStyle: {
                          "padding-left": "10px",
                          "box-sizing": "border-box",
                          display: "inline-block"
                        }
                      },
                      _vm._l(_vm.ruler.item, function(item) {
                        return _c("span", {
                          key: item.pixel,
                          class: {
                            graduation: item.isGraduation,
                            big: item.isBig
                          },
                          style: { left: item.pixel + "px" }
                        })
                      }),
                      0
                    ),
                    _c("div", [
                      _c(
                        "div",
                        {
                          ref: "canvas",
                          staticClass: "canvas",
                          staticStyle: {
                            width: "400px !important",
                            "max-width": "750px",
                            "max-height": "750px",
                            "margin-left": "0px !important",
                            "margin-top": "19px !important"
                          }
                        },
                        [
                          _c("div", {
                            style: {
                              position: "absolute",
                              width: _vm.configKonva.width + "px",
                              "max-width": "750px",
                              "max-height": "750px",
                              height:
                                _vm.templateSize.value === 23
                                  ? 158 * _vm.scale + "px"
                                  : 540 * _vm.scale + "px",
                              outline: "dashed 1px red"
                            }
                          }),
                          _c(
                            "perfect-scrollbar",
                            {
                              style: {
                                "max-width": "750px",
                                "max-height": "750px",
                                margin: "0px",
                                padding: "0px"
                              }
                            },
                            [
                              _c(
                                "v-stage",
                                {
                                  ref: "stage",
                                  attrs: {
                                    height: _vm.configKonva.height,
                                    width: _vm.configKonva.width,
                                    config: _vm.configKonva
                                  },
                                  on: {
                                    mousedown: _vm.handleStageMouseDown,
                                    touchstart: _vm.handleStageMouseDown
                                  }
                                },
                                [
                                  _c(
                                    "v-layer",
                                    { attrs: { refs: "layer" } },
                                    [
                                      _vm._l(_vm.contentsList, function(
                                        item,
                                        index
                                      ) {
                                        return [
                                          item.shapeType === "rect"
                                            ? _c("v-rect", {
                                                key: index,
                                                attrs: { config: item },
                                                on: {
                                                  dragend: _vm.handleDragEnd,
                                                  transformend:
                                                    _vm.handleTransformEnd
                                                }
                                              })
                                            : _vm._e(),
                                          item.shapeType === "ellipse"
                                            ? _c("v-ellipse", {
                                                key: index,
                                                attrs: { config: item },
                                                on: {
                                                  dragend: _vm.handleDragEnd,
                                                  transformend:
                                                    _vm.handleTransformEnd
                                                }
                                              })
                                            : _vm._e(),
                                          item.shapeType === "line"
                                            ? _c("v-line", {
                                                key: index,
                                                attrs: { config: item },
                                                on: {
                                                  dragend: _vm.handleDragEnd,
                                                  transformend:
                                                    _vm.handleTransformEnd
                                                }
                                              })
                                            : _vm._e(),
                                          item.shapeType === "triangle"
                                            ? _c("v-regular-polygon", {
                                                key: index,
                                                attrs: { config: item },
                                                on: {
                                                  dragend: _vm.handleDragEnd,
                                                  transformend:
                                                    _vm.handleTransformEnd
                                                }
                                              })
                                            : _vm._e(),
                                          item.shapeType === "image"
                                            ? _c("v-image", {
                                                key: index,
                                                attrs: { config: item },
                                                on: {
                                                  dragend: _vm.handleDragEnd,
                                                  transformend:
                                                    _vm.handleTransformEnd
                                                }
                                              })
                                            : _vm._e(),
                                          item.shapeType === "text"
                                            ? _c("v-text", {
                                                key: index,
                                                attrs: { config: item },
                                                on: {
                                                  dragend: _vm.handleDragEnd,
                                                  transformend:
                                                    _vm.handleTransformEnd
                                                }
                                              })
                                            : _vm._e(),
                                          item.shapeType === "product"
                                            ? _c("v-text", {
                                                key: index,
                                                attrs: { config: item },
                                                on: {
                                                  dragend: _vm.handleDragEnd,
                                                  transformend:
                                                    _vm.handleTransformEnd
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      }),
                                      _c("v-transformer", {
                                        ref: "transformer",
                                        attrs: {
                                          config: {
                                            ignoreStroke: true,
                                            keepRatio: false
                                          }
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-column" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-white",
                        on: { click: _vm.increment }
                      },
                      [_c("v-icon", [_vm._v("mdi-plus")])],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-white mt-2",
                        on: { click: _vm.decrement }
                      },
                      [_c("v-icon", [_vm._v("mdi-minus")])],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _c(
            "aside",
            { staticClass: "template-layout-sider" },
            [
              _c(
                "v-card",
                {
                  staticClass: "custom-scrollbar",
                  staticStyle: { "min-height": "fit-content" }
                },
                [
                  _c(
                    "v-tabs",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c("v-tab", { staticStyle: { width: "33.3%" } }, [
                        _vm._v(_vm._s(_vm.$t("TEMPLATE")))
                      ]),
                      _c("v-tab", { staticStyle: { width: "33.3%" } }, [
                        _vm._v(_vm._s(_vm.$t("CONTENTS")))
                      ]),
                      _c("v-tab", { staticStyle: { width: "33.3%" } }, [
                        _vm._v(_vm._s(_vm.$t("ANIMATION")))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        { staticClass: "custom-scrollbar" },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "card-template",
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group mb-4",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "Input template title"
                                      ),
                                      clearable: "",
                                      label: _vm.$t("Title"),
                                      dense: "",
                                      "hide-details": "",
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.template.info.title,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.template.info,
                                          "title",
                                          $$v
                                        )
                                      },
                                      expression: "template.info.title"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group mb-4 mt-4" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "form-input",
                                        attrs: {
                                          type: "number",
                                          label: _vm.$t("Size"),
                                          clearable: "",
                                          dense: "",
                                          "hide-details": "",
                                          outlined: ""
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.changeProperty(
                                              $event,
                                              "c_width"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.template.info.width,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.template.info,
                                              "width",
                                              $$v
                                            )
                                          },
                                          expression: "template.info.width"
                                        }
                                      }),
                                      _c("span", { staticClass: "px-3" }, [
                                        _vm._v("×")
                                      ]),
                                      _c("v-text-field", {
                                        staticClass: "form-input",
                                        attrs: {
                                          type: "number",
                                          clearable: "",
                                          dense: "",
                                          "hide-details": "",
                                          outlined: ""
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.changeProperty(
                                              $event,
                                              "c_height"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.template.info.height,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.template.info,
                                              "height",
                                              $$v
                                            )
                                          },
                                          expression: "template.info.height"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group mb-4 mt-4",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c("v-combobox", {
                                    staticClass: "form-select",
                                    attrs: {
                                      items: _vm.template.info.opacity.items,
                                      placeholder: _vm.$t(
                                        "Input template title"
                                      ),
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      label: _vm.$t("Opacity")
                                    },
                                    model: {
                                      value: _vm.template.info.opacity.value,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.template.info.opacity,
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "template.info.opacity.value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group form-inline mb-4 mt-4",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c("v-combobox", {
                                    staticClass: "form-select",
                                    attrs: {
                                      items: _vm.template.info.layer.items,
                                      placeholder: _vm.$t("Select Layer"),
                                      label: _vm.$t("Layer"),
                                      hint: _vm.$t(
                                        "Note : If nested with other templates, select which layers are visible."
                                      ),
                                      "persistent-hint": "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.blurTemplateConfig("layer")
                                      }
                                    },
                                    model: {
                                      value: _vm.template.info.layer.value,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.template.info.layer,
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "template.info.layer.value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group mb-3" },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "form-checkbox",
                                    style: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "Input template title"
                                      ),
                                      outlined: "",
                                      label: _vm.$t("Fixed Template"),
                                      "persistent-hint": "",
                                      "hide-details": "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.template.info.fixed,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.template.info,
                                          "fixed",
                                          $$v
                                        )
                                      },
                                      expression: "template.info.fixed"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "sub-title",
                                  staticStyle: { "margin-top": "5px" }
                                },
                                [_vm._v(_vm._s(_vm.$t("RATIO PREVIEW")))]
                              ),
                              _c("v-combobox", {
                                staticClass: "form-combobox",
                                attrs: {
                                  items: _vm.comboitems2,
                                  "hide-details": "",
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.templateSize,
                                  callback: function($$v) {
                                    _vm.templateSize = $$v
                                  },
                                  expression: "templateSize"
                                }
                              }),
                              _c("div", { staticClass: "ratio-preview mt-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex time-slider sm-line",
                                    style: {
                                      width: _vm.ratio.width + "px",
                                      height: _vm.ratio.height + "px",
                                      border: "2px #031e37 solid !important"
                                    }
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "timecell-event",
                                      style: {
                                        width: _vm.previewSize.width,
                                        height: _vm.previewSize.height
                                      }
                                    })
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { staticClass: "custom-scrollbar" },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "card-contents",
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  staticStyle: { display: "block" }
                                },
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("Contents List")))
                                  ]),
                                  _c("v-data-table", {
                                    staticClass: "gray-data-table border-table",
                                    attrs: {
                                      headers: _vm.templateHeaders,
                                      items: _vm.contentsList,
                                      height: "121",
                                      "hide-default-footer": "",
                                      "hide-default-header": ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "body",
                                        fn: function() {
                                          return [
                                            _c(
                                              "SlickList",
                                              {
                                                attrs: {
                                                  lockAxis: "y",
                                                  tag: "tbody",
                                                  lockToContainerEdges: ""
                                                },
                                                on: {
                                                  "sort-start": function(
                                                    $event
                                                  ) {
                                                    return _vm.clickEvent(
                                                      $event
                                                    )
                                                  },
                                                  "sort-end":
                                                    _vm.handleShapeListChange
                                                },
                                                model: {
                                                  value: _vm.contentsList,
                                                  callback: function($$v) {
                                                    _vm.contentsList = $$v
                                                  },
                                                  expression: "contentsList"
                                                }
                                              },
                                              _vm._l(_vm.contentsList, function(
                                                item,
                                                index
                                              ) {
                                                return _c(
                                                  "SlickItem",
                                                  {
                                                    key: index,
                                                    class: {
                                                      selectedRow:
                                                        item.id ===
                                                        _vm.selectedShapeId
                                                    },
                                                    style: {
                                                      padding: "0 24px"
                                                    },
                                                    attrs: {
                                                      index: index,
                                                      tag: "tr"
                                                    }
                                                  },
                                                  [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          index +
                                                            1 +
                                                            "_" +
                                                            item.shapeType
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              }),
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "2" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.listMoveUp()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-small": "" } },
                                                [
                                                  _vm._v(
                                                    " mdi-arrow-collapse-up "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "2" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.listMoveDown()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-small": "" } },
                                                [
                                                  _vm._v(
                                                    " mdi-arrow-collapse-down "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.selectedShapeType === "text" ||
                              _vm.selectedShapeType === "product"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "form-group",
                                      staticStyle: { display: "block" }
                                    },
                                    [
                                      _vm.selectedShapeType === "product"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Mapping Product Info"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: { "no-gutters": "" }
                                                },
                                                [
                                                  _c("product-info", {
                                                    attrs: {
                                                      productName:
                                                        _vm.selectProperty.bind
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(_vm._s(_vm.$t("Font")))
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c("v-select", {
                                            staticClass:
                                              "form-select font-select",
                                            attrs: {
                                              items:
                                                _vm.template.fontConfig.family,
                                              placeholder: "Font Family",
                                              "hide-details": "",
                                              "persistent-hint": "",
                                              outlined: "",
                                              dense: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.changeProperty(
                                                  $event,
                                                  "fontFamily"
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.selectProperty.fontFamily,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.selectProperty,
                                                  "fontFamily",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectProperty.fontFamily"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "4" } },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "font-center",
                                                    attrs: {
                                                      type: "number",
                                                      placeholder: "Font Size",
                                                      "hide-details": "",
                                                      "persistent-hint": "",
                                                      outlined: "",
                                                      dense: ""
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.changeProperty(
                                                          $event,
                                                          "fontSize"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectProperty
                                                          .fontSize,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectProperty,
                                                          "fontSize",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectProperty.fontSize"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "8" } },
                                                [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "form-select font-select ml-6",
                                                    attrs: {
                                                      items:
                                                        _vm.template.fontConfig
                                                          .style,
                                                      placeholder: "Font Style",
                                                      "hide-details": "",
                                                      "persistent-hint": "",
                                                      outlined: "",
                                                      dense: ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.changeProperty(
                                                          $event,
                                                          "fontStyle"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectProperty
                                                          .fontStyle,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectProperty,
                                                          "fontStyle",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectProperty.fontStyle"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass:
                                                  "form-select font-center",
                                                attrs: {
                                                  type: "number",
                                                  placeholder: "spacing",
                                                  "prepend-icon": "mdi-magnify",
                                                  "hide-details": "",
                                                  "persistent-hint": "",
                                                  outlined: "",
                                                  dense: ""
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.changeProperty(
                                                      $event,
                                                      "letterSpacing"
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "prepend",
                                                      fn: function() {
                                                        return [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/img/ico_text_indent.png")
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3378856461
                                                ),
                                                model: {
                                                  value:
                                                    _vm.selectProperty
                                                      .letterSpacing,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selectProperty,
                                                      "letterSpacing",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectProperty.letterSpacing"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeProperty(
                                                                $event,
                                                                "alignLeft"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/img/ico_left_align.png")
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeProperty(
                                                                $event,
                                                                "alignCenter"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/img/ico_center_align.png")
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeProperty(
                                                                $event,
                                                                "alignRight"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/img/ico_right_align.png")
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.selectedShapeType === "text"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group mb-4 mt-2",
                                              staticStyle: { width: "100%" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "form-input",
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "Input text"
                                                  ),
                                                  label: _vm.$t("Text"),
                                                  clearable: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                  outlined: ""
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.changeProperty(
                                                      $event,
                                                      "text"
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectProperty.text,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selectProperty,
                                                      "text",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectProperty.text"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("label", [
                                            _vm._v(_vm._s(_vm.$t("Size")))
                                          ]),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "align-center mt-3",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [_c("label", [_vm._v("W")])]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "4" } },
                                                [
                                                  _c("v-text-field", {
                                                    ref: "shapeWidth",
                                                    staticClass:
                                                      "form-group text-center mr-2",
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "",
                                                      outlined: "",
                                                      dense: "",
                                                      "single-line": "",
                                                      disabled:
                                                        _vm.selectedShapeId ===
                                                        0
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.changeProperty(
                                                          $event,
                                                          "width"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectProperty
                                                          .width,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectProperty,
                                                          "width",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectProperty.width"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [_c("label", [_vm._v("H")])]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "4" } },
                                                [
                                                  _c("v-text-field", {
                                                    ref: "shapeHeight",
                                                    staticClass:
                                                      "text-center mr-2",
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "",
                                                      dense: "",
                                                      outlined: "",
                                                      "single-line": "",
                                                      disabled:
                                                        _vm.selectedShapeId ===
                                                        0
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.changeProperty(
                                                          $event,
                                                          "height"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectProperty
                                                          .height,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectProperty,
                                                          "height",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectProperty.height"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "align-center mt-2",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [_c("label", [_vm._v("X")])]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "4" } },
                                                [
                                                  _c("v-text-field", {
                                                    ref: "shapeX",
                                                    staticClass:
                                                      "text-center mr-2",
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "",
                                                      outlined: "",
                                                      dense: "",
                                                      "single-line": "",
                                                      disabled:
                                                        _vm.selectedShapeId ===
                                                        0
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.changeProperty(
                                                          $event,
                                                          "x"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectProperty.x,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectProperty,
                                                          "x",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectProperty.x"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [_c("label", [_vm._v("Y")])]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "4" } },
                                                [
                                                  _c("v-text-field", {
                                                    ref: "shapeY",
                                                    staticClass:
                                                      "text-center mr-2",
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "",
                                                      outlined: "",
                                                      dense: "",
                                                      "single-line": "",
                                                      disabled:
                                                        _vm.selectedShapeId ===
                                                        0
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.changeProperty(
                                                          $event,
                                                          "y"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectProperty.y,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectProperty,
                                                          "y",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectProperty.y"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mt-4",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(_vm._s(_vm.$t("Option")))
                                          ]),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "align-center mt-2",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("Opacity"))
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    ref: "shapeOpacity",
                                                    staticClass: "text-center",
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "",
                                                      outlined: "",
                                                      dense: "",
                                                      "single-line": "",
                                                      min: "0",
                                                      max: "100",
                                                      disabled:
                                                        _vm.selectedShapeId ===
                                                        0
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.changeProperty(
                                                          $event,
                                                          "opacity"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectProperty
                                                          ._opacity,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectProperty,
                                                          "_opacity",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectProperty._opacity"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "align-center mt-4",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("Rotate"))
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    ref: "shapeRotate",
                                                    staticClass: "text-center",
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "",
                                                      dense: "",
                                                      outlined: "",
                                                      "single-line": "",
                                                      disabled:
                                                        _vm.selectedShapeId ===
                                                        0
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.changeProperty(
                                                          $event,
                                                          "rotate"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectProperty
                                                          .rotation,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectProperty,
                                                          "rotation",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectProperty.rotation"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.selectProperty.shapeType === "product" ||
                              _vm.selectProperty.shapeType === "text"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "form-group mt-4",
                                      staticStyle: { display: "block" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  label: "Fit",
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.changeProperty(
                                                      $event,
                                                      "fit"
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.selectProperty.fit,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selectProperty,
                                                      "fit",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectProperty.fit"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticStyle: {
                                                "margin-top": "16px"
                                              },
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass: "form-select",
                                                attrs: {
                                                  label: "Wrap",
                                                  disabled:
                                                    _vm.selectProperty.fit,
                                                  items: ["word", "char"],
                                                  outlined: "",
                                                  dense: "",
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.changeProperty(
                                                      $event,
                                                      "wrap"
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectProperty.wrap,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selectProperty,
                                                      "wrap",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectProperty.wrap"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticStyle: {
                                                "margin-top": "16px"
                                              },
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "ml-2 form-input",
                                                attrs: {
                                                  label: "Line",
                                                  type: "number",
                                                  disabled:
                                                    _vm.selectProperty.fit,
                                                  outlined: "",
                                                  dense: "",
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.changeProperty(
                                                      $event,
                                                      "line"
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectProperty.line,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selectProperty,
                                                      "line",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "selectProperty.line"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.selectProperty.shapeType !== "image"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "form-group mt-4",
                                      staticStyle: { display: "block" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.selectProperty.shapeType !==
                                                "line",
                                              expression:
                                                "selectProperty.shapeType !== 'line'"
                                            }
                                          ],
                                          staticClass: "align-center mt-3",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("label", [
                                                _vm._v(_vm._s(_vm.$t("Shape")))
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mt-4",
                                              attrs: { cols: "2" }
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(_vm._s(_vm.$t("Fill")))
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mt-4",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c(
                                                "center",
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "offset-y": "",
                                                        "close-on-content-click": false
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "color-control",
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm.selectedShapeId ===
                                                                          0,
                                                                        color:
                                                                          _vm
                                                                            .selectProperty
                                                                            .fill,
                                                                        dark:
                                                                          "",
                                                                        small:
                                                                          ""
                                                                      }
                                                                    },
                                                                    on
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        1092273822
                                                      )
                                                    },
                                                    [
                                                      _c("v-color-picker", {
                                                        staticClass: "mx-auto",
                                                        attrs: { flat: "" },
                                                        on: {
                                                          "update:color": function(
                                                            $event
                                                          ) {
                                                            return _vm.changeProperty(
                                                              $event,
                                                              "fill"
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectProperty
                                                              .fill,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.selectProperty,
                                                              "fill",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "selectProperty.fill"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mt-4",
                                              attrs: { cols: "2" }
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(_vm._s(_vm.$t("Stroke")))
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mt-4",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c(
                                                "center",
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "offset-y": "",
                                                        "close-on-content-click": false
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "color-control",
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm.selectedShapeId ===
                                                                          0,
                                                                        color:
                                                                          _vm
                                                                            .selectProperty
                                                                            .stroke,
                                                                        dark:
                                                                          "",
                                                                        small:
                                                                          ""
                                                                      }
                                                                    },
                                                                    on
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        308944389
                                                      )
                                                    },
                                                    [
                                                      _c("v-color-picker", {
                                                        staticClass: "mx-auto",
                                                        attrs: { flat: "" },
                                                        on: {
                                                          "update:color": function(
                                                            $event
                                                          ) {
                                                            return _vm.changeProperty(
                                                              $event,
                                                              "stroke"
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectProperty
                                                              .stroke,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.selectProperty,
                                                              "stroke",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "selectProperty.stroke"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.selectProperty.shapeType !== "image"
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "align-center mt-3",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "align-center",
                                                      attrs: {
                                                        "no-gutters": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "5" }
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Stroke Width"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "7" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "text-center ml-3",
                                                            style: {
                                                              width: "80%"
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                _vm.selectedShapeId ===
                                                                0,
                                                              type: "number",
                                                              "hide-details":
                                                                "",
                                                              outlined: "",
                                                              dense: "",
                                                              "single-line": ""
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                return _vm.changeProperty(
                                                                  $event,
                                                                  "strokeWidth"
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .selectProperty
                                                                  .strokeWidth,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.selectProperty,
                                                                  "strokeWidth",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "selectProperty.strokeWidth"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { staticStyle: { height: "700px" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "card-animation",
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group mb-4 col",
                                  staticStyle: {
                                    padding: "0",
                                    display: "flow-root"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("v-combobox", {
                                        staticClass: "form-select",
                                        attrs: {
                                          items:
                                            _vm.template.animation.actionTime
                                              .list,
                                          placeholder: _vm.$t("Direct Input"),
                                          label: _vm.$t("Action Time(s)"),
                                          "hide-details": "",
                                          outlined: "",
                                          dense: ""
                                        },
                                        on: {
                                          change: _vm.handleActionTimeChange
                                        },
                                        model: {
                                          value:
                                            _vm.template.animation.actionTime
                                              .selected,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.template.animation.actionTime,
                                              "selected",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "template.animation.actionTime.selected"
                                        }
                                      }),
                                      _vm.template.animation.actionTime.selected
                                        .text === "Direct Input"
                                        ? _c("v-text-field", {
                                            staticClass: "form-input ml-3",
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "Action Times"
                                              ),
                                              clearable: "",
                                              dense: "",
                                              "hide-details": "",
                                              outlined: ""
                                            },
                                            on: {
                                              blur:
                                                _vm.handleActionTimeDirectInputBlur
                                            },
                                            model: {
                                              value:
                                                _vm.template.animation
                                                  .actionTime.selected.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.template.animation
                                                    .actionTime.selected,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "template.animation.actionTime.selected.value"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group col mb-3 mt-4",
                                  staticStyle: { padding: "0" }
                                },
                                [
                                  _c("v-combobox", {
                                    staticClass: "form-select",
                                    attrs: {
                                      items:
                                        _vm.template.animation.actionEffect
                                          .list,
                                      placeholder: _vm.$t("None"),
                                      "hide-details": "",
                                      outlined: "",
                                      label: _vm.$t("Action Effect"),
                                      dense: ""
                                    },
                                    model: {
                                      value:
                                        _vm.template.animation.actionEffect
                                          .selected,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.template.animation.actionEffect,
                                          "selected",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "template.animation.actionEffect.selected"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group mb-2",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c("label", { staticClass: "label-txt" }, [
                                    _vm._v(_vm._s(_vm.$t("Action Shape")))
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group mb-4",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn",
                                      style: { width: "49%" },
                                      attrs: { text: "" },
                                      on: { click: _vm.openStartAnimation }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Start")) + " ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn ml-3",
                                      style: { width: "49%" },
                                      attrs: { text: "" },
                                      on: { click: _vm.openEndAnimation }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Ending")) + " ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group mb-4 mt-4" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-center",
                                      staticStyle: { width: "100%" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "form-input",
                                        attrs: {
                                          placeholder: _vm.$t("10"),
                                          clearable: "",
                                          dense: "",
                                          label: _vm.$t("Holding Time"),
                                          "hide-details": "",
                                          outlined: ""
                                        },
                                        model: {
                                          value:
                                            _vm.template.animation.holdingTime,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.template.animation,
                                              "holdingTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "template.animation.holdingTime"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "ml-3 caption" },
                                        [_vm._v(_vm._s(_vm.$t("seconds")))]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group mb-4 mt-4",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c("v-combobox", {
                                    staticClass: "form-select",
                                    attrs: {
                                      items:
                                        _vm.template.animation.trigger.list,
                                      placeholder: _vm.$t("Another Template"),
                                      "hide-details": "",
                                      label: _vm.$t("Trigger"),
                                      outlined: "",
                                      dense: ""
                                    },
                                    on: { change: _vm.handleTriggerChange },
                                    model: {
                                      value:
                                        _vm.template.animation.trigger.selected,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.template.animation.trigger,
                                          "selected",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "template.animation.trigger.selected"
                                    }
                                  }),
                                  _vm.template.animation.trigger.selected ===
                                  "Another Template"
                                    ? _c("trigger-dialog", {
                                        attrs: {
                                          triggerTemplate:
                                            _vm.template.animation.trigger.item
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group mb-2 mt-4",
                                  staticStyle: {
                                    width: "100%",
                                    display: "flow-root"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("v-combobox", {
                                        staticClass: "form-select",
                                        attrs: {
                                          items:
                                            _vm.template.animation
                                              .returnToTrigger.list,
                                          placeholder: _vm.$t("Direct Input"),
                                          label: _vm.$t("Return to Trigger"),
                                          "hide-details": "",
                                          outlined: "",
                                          dense: ""
                                        },
                                        model: {
                                          value:
                                            _vm.template.animation
                                              .returnToTrigger.selected,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.template.animation
                                                .returnToTrigger,
                                              "selected",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "template.animation.returnToTrigger.selected"
                                        }
                                      }),
                                      _vm.template.animation.returnToTrigger
                                        .selected === "Time (Direct Input)"
                                        ? _c("v-text-field", {
                                            staticClass: "ml-3 text-center",
                                            attrs: {
                                              "hide-details": "",
                                              "single-line": "",
                                              value: "0"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "sub-title",
                                  staticStyle: {
                                    "margin-top": "30px !important"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("PREVIEW")))]
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ratio-preview mt-1 mb-6",
                                    staticStyle: { overflow: "hidden" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "210px",
                                          height: "130px",
                                          "align-self": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "210px",
                                              height: "130px",
                                              position: "absolute",
                                              display: "flex",
                                              "justify-content": "center",
                                              "align-self": "center",
                                              "margin-right": "25px",
                                              cursor: "pointer"
                                            },
                                            on: { click: _vm.previewClick }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                style: {
                                                  display:
                                                    _vm.animationStatus.start ||
                                                    _vm.animationStatus.end
                                                      ? "none"
                                                      : ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-play-circle-outline"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            class: _vm.getAnimationState(),
                                            style: {
                                              "background-image":
                                                "url(" + _vm.previewImage + ")",
                                              "background-size":
                                                _vm.previewWidth +
                                                "px" +
                                                " " +
                                                _vm.previewHeight +
                                                "px",
                                              width: _vm.previewImage.width,
                                              height: _vm.previewImage.height,
                                              "margin-left":
                                                Number(
                                                  (210 - _vm.previewWidth) / 2
                                                ) + "px",
                                              "margin-top":
                                                Number(
                                                  (130 - _vm.previewHeight) / 2
                                                ) + "px"
                                            }
                                          },
                                          [
                                            _c("div", {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content":
                                                  "space-around",
                                                height: "130px"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("br"),
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-5" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn d-block width-full",
                              staticStyle: { width: "100px" },
                              attrs: { text: "" },
                              on: { click: _vm.saveAnimation }
                            },
                            [_vm._v(_vm._s(_vm.$t("Save")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-5",
                          staticStyle: { width: "150px" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn d-block ml-2 width-full",
                              staticStyle: { width: "100px" },
                              attrs: { text: "" },
                              on: { click: _vm.cancelAnimation }
                            },
                            [_vm._v(_vm._s(_vm.$t("Cancel")))]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "v-dialog",
        {
          staticClass: "rounded-0",
          attrs: { width: "650" },
          on: { "click:outside": _vm.startAnimationClose },
          model: {
            value: _vm.startDialog,
            callback: function($$v) {
              _vm.startDialog = $$v
            },
            expression: "startDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("h3", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-template" }),
                _vm._v(" " + _vm._s(_vm.$t("Start Animation")) + " ")
              ]),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("div", { staticClass: "form-group mb-4" }, [
                          _c("label", [_vm._v(_vm._s(_vm.$t("Opacity")))]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("v-text-field", {
                                staticClass: "form-input",
                                attrs: {
                                  counter: "",
                                  maxlength: "3",
                                  dense: "",
                                  "hide-details": "",
                                  outlined: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.inputNumber(
                                      $event,
                                      "startOpacity"
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.template.animation.start.opacity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.template.animation.start,
                                      "opacity",
                                      $$v
                                    )
                                  },
                                  expression: "template.animation.start.opacity"
                                }
                              }),
                              _c("span", { staticClass: "px-3" }, [_vm._v("%")])
                            ],
                            1
                          )
                        ])
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "ml-5", attrs: { cols: "4" } },
                        [
                          _c("div", { staticClass: "form-group mb-4" }, [
                            _c("label", [_vm._v(_vm._s(_vm.$t("Location")))]),
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("span", { staticClass: "pr-3" }, [
                                  _vm._v("X")
                                ]),
                                _c("v-text-field", {
                                  staticClass: "form-input",
                                  attrs: {
                                    type: "number",
                                    dense: "",
                                    counter: "",
                                    maxlength: "5",
                                    "hide-details": "",
                                    outlined: ""
                                  },
                                  model: {
                                    value: _vm.template.animation.start.x,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.template.animation.start,
                                        "x",
                                        $$v
                                      )
                                    },
                                    expression: "template.animation.start.x"
                                  }
                                }),
                                _c("span", { staticClass: "pl-5 pr-3" }, [
                                  _vm._v("Y")
                                ]),
                                _c("v-text-field", {
                                  staticClass: "form-input",
                                  attrs: {
                                    type: "number",
                                    dense: "",
                                    counter: "",
                                    maxlength: "4",
                                    "hide-details": "",
                                    outlined: ""
                                  },
                                  model: {
                                    value: _vm.template.animation.start.y,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.template.animation.start,
                                        "y",
                                        $$v
                                      )
                                    },
                                    expression: "template.animation.start.y"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "caption text-left pb-2" }, [
                    _vm._v(_vm._s(_vm.$t("Location")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "selected-template-box" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column ml-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-white",
                              on: { click: _vm.startIncrement }
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-white mt-2",
                              on: { click: _vm.startDecrement }
                            },
                            [_c("v-icon", [_vm._v("mdi-minus")])],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "perfect-scrollbar",
                        {
                          ref: "sps",
                          style: { width: "468px", height: "224px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                margin: "50px",
                                "padding-left": "50px",
                                width: "2000px"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "animation-preview",
                                  style: {
                                    transform:
                                      "scale(" +
                                      _vm.actionShape.startScale +
                                      ")",
                                    "transform-origin": "left top"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        outline: "solid 10px black"
                                      },
                                      style: {
                                        width: 1920 + "px",
                                        height:
                                          (_vm.templateSize.value === 23
                                            ? 158
                                            : 540) + "px"
                                      }
                                    },
                                    [
                                      _c("div", {
                                        style: {
                                          position: "relative",
                                          "background-image":
                                            "url(" + _vm.animationImage + ")",
                                          "background-size":
                                            _vm.template.info.width +
                                            "px" +
                                            " " +
                                            _vm.template.info.height +
                                            "px",
                                          "margin-left":
                                            _vm.template.animation.start.x +
                                            "px",
                                          "margin-top":
                                            _vm.template.animation.start.y +
                                            "px",
                                          width: _vm.template.info.width + "px",
                                          height:
                                            _vm.template.info.height + "px",
                                          opacity: Number(
                                            _vm.template.animation.start
                                              .opacity / 100
                                          )
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.startAnimationSave }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.startAnimationClose }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "rounded-0",
          attrs: { width: "650" },
          on: { "click:outside": _vm.endAnimationClose },
          model: {
            value: _vm.endingDialog,
            callback: function($$v) {
              _vm.endingDialog = $$v
            },
            expression: "endingDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("h3", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-template" }),
                _vm._v(_vm._s(_vm.$t("Ending Animation")) + " ")
              ]),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("div", { staticClass: "form-group mb-4" }, [
                          _c("label", [_vm._v(_vm._s(_vm.$t("Opacity")))]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("v-text-field", {
                                staticClass: "form-input",
                                attrs: {
                                  counter: "",
                                  maxlength: "3",
                                  dense: "",
                                  "hide-details": "",
                                  outlined: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.inputNumber($event, "endOpacity")
                                  }
                                },
                                model: {
                                  value: _vm.template.animation.end.opacity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.template.animation.end,
                                      "opacity",
                                      $$v
                                    )
                                  },
                                  expression: "template.animation.end.opacity"
                                }
                              }),
                              _c("span", { staticClass: "px-3" }, [_vm._v("%")])
                            ],
                            1
                          )
                        ])
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "ml-5", attrs: { cols: "4" } },
                        [
                          _c("div", { staticClass: "form-group mb-4" }, [
                            _c("label", [_vm._v(_vm._s(_vm.$t("Location")))]),
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("span", { staticClass: "pr-3" }, [
                                  _vm._v("X")
                                ]),
                                _c("v-text-field", {
                                  staticClass: "form-input",
                                  attrs: {
                                    type: "number",
                                    dense: "",
                                    "hide-details": "",
                                    outlined: ""
                                  },
                                  model: {
                                    value: _vm.template.animation.end.x,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.template.animation.end,
                                        "x",
                                        $$v
                                      )
                                    },
                                    expression: "template.animation.end.x"
                                  }
                                }),
                                _c("span", { staticClass: "pl-5 pr-3" }, [
                                  _vm._v("Y")
                                ]),
                                _c("v-text-field", {
                                  staticClass: "form-input",
                                  attrs: {
                                    type: "number",
                                    dense: "",
                                    "hide-details": "",
                                    outlined: ""
                                  },
                                  model: {
                                    value: _vm.template.animation.end.y,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.template.animation.end,
                                        "y",
                                        $$v
                                      )
                                    },
                                    expression: "template.animation.end.y"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "caption text-left pb-2" }, [
                    _vm._v(_vm._s(_vm.$t("Location")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "selected-template-box" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column ml-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-white",
                              on: { click: _vm.endingIncrement }
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-white mt-2",
                              on: { click: _vm.endingDecrement }
                            },
                            [_c("v-icon", [_vm._v("mdi-minus")])],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "perfect-scrollbar",
                        {
                          ref: "eps",
                          style: { width: "468px", height: "224px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                margin: "50px",
                                "padding-left": "50px",
                                width: "2000px"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "animation-preview",
                                  style: {
                                    transform:
                                      "scale(" + _vm.actionShape.endScale + ")",
                                    "transform-origin": "left top"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        outline: "solid 10px black"
                                      },
                                      style: {
                                        width: 1920 + "px",
                                        height:
                                          (_vm.templateSize.value === 23
                                            ? 158
                                            : 540) + "px"
                                      }
                                    },
                                    [
                                      _c("div", {
                                        style: {
                                          position: "relative",
                                          "background-image":
                                            "url(" + _vm.animationImage + ")",
                                          "background-size":
                                            _vm.template.info.width +
                                            "px" +
                                            " " +
                                            _vm.template.info.height +
                                            "px",
                                          "margin-left":
                                            _vm.template.animation.end.x + "px",
                                          "margin-top":
                                            _vm.template.animation.end.y + "px",
                                          width: _vm.template.info.width + "px",
                                          height:
                                            _vm.template.info.height + "px",
                                          opacity: Number(
                                            _vm.template.animation.end.opacity /
                                              100
                                          )
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.endAnimationSave }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.endAnimationClose }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("get-template", { attrs: { getTemplateDialog: _vm.templateDialog } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }