<template>
  <div>
    <div class="template-layout" style="min-height: 1134px; width: 1380px">
      <div class="template-layout-container">
        <div class="template-layout-content">
          <h2 class="page-title-bar">
            <i class="ico ico-template"></i>{{ $t("Edit Template") }}
          </h2>
          <!-- <v-row>
          <v-col cols="2"> <v-checkbox label="fit" v-model="selectProperty.fit" @change="changeProperty($event, 'fit')"/> </v-col>
          <v-col cols="2"> <v-select label="wrap" :disabled="selectProperty.fit" :items="['word', 'char']" v-model="selectProperty.wrap" @change="changeProperty($event, 'wrap')"/> </v-col>
          <v-col cols="2"> <v-text-field label="line" :disabled="selectProperty.fit" v-model.number="selectProperty.line" @change="changeProperty($event, 'line')"/> </v-col>
          </v-row> -->
          <div class="unit-select" style="height: 38px">
            <!-- <v-btn small disabled>Unit</v-btn>
            <v-combobox
              :items="unitOption"
              :placeholder="$t('mm')"
              :style="{ width: '62px' }"
              class="form-combobox"
              hide-details
              outlined
              solo>
            </v-combobox> -->
          </div>
          <div class="template-grid">
            <div class="tools">
              <div>
                <!-- <v-btn class="btn-white" id="undo" @click="undoEvent" title="Undo">
                  <img src="@/assets/img/icon-reply.png" />
                </v-btn>
                <v-btn class="btn-white" @click="redoEvent" title="Redo">
                  <img src="@/assets/img/icon-share.png" />
                </v-btn> -->
                <!-- <v-btn class="btn-white" @click="addText" title="Text">
                  <img src="@/assets/img/icon-format-text-variant.png" />
                </v-btn>
                <v-btn class="btn-white" @click="addProduct" title="Product">
                  <img src="@/assets/img/icon-alert-circle.png" />
                </v-btn>
                <v-btn class="btn-white" @click="addEllipse" title="Circle">
                  <img src="@/assets/img/icon-circle-outline.png" />
                </v-btn>
                <v-btn class="btn-white" @click="addRect" title="Rectangle">
                  <img src="@/assets/img/icon-checkbox-blank.png" />
                </v-btn>
                <v-btn class="btn-white" @click="addTriangle" title="Triangle">
                  <img src="@/assets/img/icon-triangle.png" />
                </v-btn>
                <v-btn class="btn-white" @click="addLine" title="Line">
                  <img src="@/assets/img/icon-slash-forward.png" />
                </v-btn>
                <v-btn class="btn-white" @click="getFile" title="Image">
                  <img src="@/assets/img/icon-file-image.png" />
                </v-btn>
                <input
                  @change="changeFile"
                  id="add_image"
                  ref="add_image"
                  style="display: none"
                  type="file" />
                <v-btn class="btn-white" @click="getTemplate" title="Template">
                  <img src="@/assets/img/icon-view-quilt.png" />
                </v-btn>
                <v-btn
                  @click="delShapes"
                  class="btn-white"
                  style="width: 88px"
                  title="Trash">
                  <img src="@/assets/img/icon-trash-can.png" />
                </v-btn> -->

                <v-tooltip right>
                  <template v-slot:activator=" { on, attrs }">
                    <v-btn class="btn-white" icon @click="addText" v-on="on" v-bind="attrs">
                      <img src="@/assets/img/icon-format-text-variant.png" />
                    </v-btn>
                  </template>
                  <span>{{$t('Add Text')}}</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator=" { on, attrs }">
                    <v-btn class="btn-white" @click="addProduct" v-on="on" v-bind="attrs">
                      <img src="@/assets/img/icon-alert-circle.png" />
                    </v-btn>
                  </template>
                  <span>{{$t('Add Product')}}</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator=" { on, attrs }">
                    <v-btn class="btn-white" @click="addEllipse" v-on="on" v-bind="attrs">
                      <img src="@/assets/img/icon-circle-outline.png" />
                    </v-btn>
                  </template>
                  <span>{{$t('Add Circle')}}</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator=" { on, attrs }">
                    <v-btn class="btn-white" @click="addRect" v-on="on" v-bind="attrs">
                      <img src="@/assets/img/icon-checkbox-blank.png" />
                    </v-btn>
                  </template>
                  <span>{{$t('Add Rectangle')}}</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator=" { on, attrs }">
                    <v-btn class="btn-white" @click="addTriangle" v-on="on" v-bind="attrs">
                      <img src="@/assets/img/icon-triangle.png" />
                    </v-btn>
                  </template>
                  <span>{{$t('Add Triangle')}}</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator=" { on, attrs }">
                    <v-btn class="btn-white" @click="addLine" v-on="on" v-bind="attrs">
                      <img src="@/assets/img/icon-slash-forward.png" />
                    </v-btn>
                  </template>
                  <span>{{$t('Add Line')}}</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator=" { on, attrs }">
                    <v-btn class="btn-white" @click="getFile" v-on="on" v-bind="attrs">
                      <img src="@/assets/img/icon-file-image.png" />
                    </v-btn>
                  </template>
                  <span>{{$t('Add Image')}}</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator=" { on, attrs }">
                    <v-btn class="btn-white" @click="getTemplate" v-on="on" v-bind="attrs">
                      <img src="@/assets/img/icon-view-quilt.png" />
                    </v-btn>
                  </template>
                  <span>{{$t('Import Template')}}</span>
                </v-tooltip>
                <!-- <input
                  @change="changeFile"
                  id="add_image"
                  ref="add_image"
                  style="display: none"
                  type="file" /> -->
                <v-file-input
                  v-model="inputFile"
                  ref="add_image"
                  accept=".jpg, .png"
                  @change="changeFile"
                  style="display: none"/>
                <v-tooltip right>
                  <template v-slot:activator=" { on, attrs }">
                    <v-btn
                      @click="delShapes"
                      class="btn-white"
                      style="width: 88px"
                       v-on="on"
                       v-bind="attrs">
                      <img src="@/assets/img/icon-trash-can.png" />
                    </v-btn>
                  </template>
                  <span>{{$t('Delete Shape')}}</span>
                </v-tooltip>
              </div>
              <v-divider></v-divider>
              <!-- <div>
                <v-btn class="btn-white" @click="alignVerticalTop" title="Align Top">
                  <img src="@/assets/img/icon-align-vertical-top.png" />
                </v-btn>
                <v-btn class="btn-white" @click="alignHorizontalLeft" title="Align Left">
                  <img src="@/assets/img/icon-align-horizontal-left.png" />
                </v-btn>
                <v-btn class="btn-white" @click="alignVerticalCenter" title="Align Center">
                  <img src="@/assets/img/icon-align-vertical-center.png" />
                </v-btn>
                <v-btn class="btn-white" @click="alignHorizontalCenter" title="Align Middle">
                  <img src="@/assets/img/icon-align-horizontal-center.png" />
                </v-btn>
                <v-btn class="btn-white" @click="alignVerticalBottom" title="Align Bottom">
                  <img src="@/assets/img/icon-align-vertical-bottom.png" />
                </v-btn>
                <v-btn class="btn-white" @click="alignHorizontalRight" title="Align Right">
                  <img src="@/assets/img/icon-align-horizontal-right.png" />
                </v-btn>
              </div> -->
            </div>
            <div class="template-canvas" ref="templateCanvas">
              <div class="yAxis" style="padding-top: 10px; box-sizing: border-box; display: inline-block;">
                <span v-for="item in ruler.item" v-bind:key="item.pixel" v-bind:class="{ graduation: item.isGraduation, big: item.isBig }" v-bind:style="{ top: item.pixel + 'px' }">
                </span>
              </div>
              <div class="xAxis" style="padding-left: 10px; box-sizing: border-box; display: inline-block;">
                <span v-for="item in ruler.item" v-bind:key="item.pixel" v-bind:class="{ graduation: item.isGraduation, big: item.isBig }" v-bind:style="{ left: item.pixel + 'px'}">
                </span>
              </div>

              <div>
              <!-- CANVAS -->
                <div
                  style="width: 400px !important; max-width: 750px; max-height: 750px; margin-left: 0px !important; margin-top: 19px !important"
                  class="canvas"
                  ref="canvas">
                  <div :style="{ position: 'absolute', width: configKonva.width + 'px', 'max-width': '750px', 'max-height': '750px', height: templateSize.value === 23 ? 158 * scale + 'px' : 540 * scale + 'px', outline: 'dashed 1px red' }"></div>
                  <perfect-scrollbar :style="{ 'max-width': '750px', 'max-height': '750px', margin: '0px', padding: '0px' }">
                  <!-- <v-stage
                    v-bind:height="configKonva.height"
                    v-bind:width="configKonva.width"
                    :config="configKonva"
                    @mousedown="handleStageMouseDown"
                    @touchstart="handleStageMouseDown"
                    @mouseup="handleStageMouseUp"
                    @mousemove="handleStageMouseMove"
                    ref="stage"> -->
                  <v-stage
                    v-bind:height="configKonva.height"
                    v-bind:width="configKonva.width"
                    :config="configKonva"
                    @mousedown="handleStageMouseDown"
                    @touchstart="handleStageMouseDown"
                    ref="stage">
                    <v-layer refs="layer">
                      <template v-for="(item, index) in contentsList">
                        <v-rect
                          :key="index"
                          v-if="item.shapeType === 'rect'"
                          :config="item"
                          @dragend="handleDragEnd"
                          @transformend="handleTransformEnd">
                        </v-rect>
                        <v-ellipse
                          :key="index"
                          v-if="item.shapeType === 'ellipse'"
                          :config="item"
                          @dragend="handleDragEnd"
                          @transformend="handleTransformEnd">
                        </v-ellipse>
                        <v-line
                          :key="index"
                          v-if="item.shapeType === 'line'"
                          :config="item"
                          @dragend="handleDragEnd"
                          @transformend="handleTransformEnd">
                        </v-line>
                        <v-regular-polygon
                          :key="index"
                          v-if="item.shapeType === 'triangle'"
                          :config="item"
                          @dragend="handleDragEnd"
                          @transformend="handleTransformEnd">
                        </v-regular-polygon>
                        <v-image
                          :key="index"
                          v-if="item.shapeType === 'image'"
                          :config="item"
                          @dragend="handleDragEnd"
                          @transformend="handleTransformEnd">
                        </v-image>
                        <v-text
                          :key="index"
                          v-if="item.shapeType === 'text'"
                          :config="item"
                          @dragend="handleDragEnd"
                          @transformend="handleTransformEnd">
                        </v-text>
                        <v-text
                          :key="index"
                          v-if="item.shapeType === 'product'"
                          :config="item"
                          @dragend="handleDragEnd"
                          @transformend="handleTransformEnd">
                        </v-text>
                      </template>
                      <v-transformer ref="transformer" :config="{ ignoreStroke: true, keepRatio: false }" />
                    </v-layer>
                  </v-stage>
                  </perfect-scrollbar>
                </div>
              <!-- CANVAS -->
              </div>
            </div>
            <div class="d-flex flex-column">
              <!-- 확대 아이콘 -->
              <v-btn class="btn-white" @click="increment">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <!-- 축소 아이콘 -->
              <v-btn class="btn-white mt-2" @click="decrement">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <!-- 선택 아이콘 -->
              <!-- <v-btn class="btn-white mt-2" @click="selectArea">
                <v-icon>mdi-blur</v-icon>
              </v-btn> -->
            </div>
          </div>
        </div>
      </div>
      <aside class="template-layout-sider">
        <v-card style="min-height:fit-content" class="custom-scrollbar">
          <v-tabs v-model="tab">
            <v-tab style="width: 33.3%;">{{$t('TEMPLATE')}}</v-tab>
            <v-tab style="width: 33.3%;">{{$t('CONTENTS')}}</v-tab>
            <v-tab style="width: 33.3%;">{{$t('ANIMATION')}}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!-- TEMPLATE Tab Area -->
            <v-tab-item class="custom-scrollbar">
              <v-card flat class="card-template">
                <div class="form-group mb-4" style="width:100%">
                  <!-- <label>Title</label> -->
                  <v-text-field
                    :placeholder="$t('Input template title')"
                    class="form-input"
                    clearable
                    :label="$t('Title')"
                    dense
                    hide-details
                    outlined
                    v-model="template.info.title">
                  </v-text-field>
                </div>
                <div class="form-group mb-4 mt-4">
                  <!-- <label>Size</label> -->
                  <div class="d-flex align-center">
                    <v-text-field
                      type="number"
                      :label="$t('Size')"
                      @input="changeProperty($event, 'c_width')"
                      class="form-input"
                      clearable
                      dense
                      hide-details
                      outlined
                      v-model="template.info.width">
                    </v-text-field>
                    <span class="px-3">×</span>
                    <v-text-field
                      type="number"
                      @input="changeProperty($event, 'c_height')"
                      class="form-input"
                      clearable
                      dense
                      hide-details
                      outlined
                      v-model="template.info.height">
                    </v-text-field>
                  </div>
                </div>
                <div class="form-group mb-4 mt-4" style="width:100%">
                  <!-- <label>Opacity</label> -->
                  <v-combobox
                    :items="template.info.opacity.items"
                    :placeholder="$t('Input template title')"
                    class="form-select"
                    outlined
                    dense
                    hide-details
                    :label="$t('Opacity')"
                    v-model="template.info.opacity.value">
                  </v-combobox>
                </div>
                <div class="form-group form-inline mb-4 mt-4" style="width:100%">
                  <!-- <label>Layer</label> -->
                  <v-combobox
                    :items="template.info.layer.items"
                    :placeholder="$t('Select Layer')"
                    @blur="blurTemplateConfig('layer')"
                    class="form-select"
                    :label="$t('Layer')"
                    :hint="$t('Note : If nested with other templates, select which layers are visible.')"
                    persistent-hint
                    outlined
                    dense
                    v-model="template.info.layer.value">
                  </v-combobox>
                </div>
                <div class="form-group mb-3">
                  <!-- <label>Fixed Template</label> -->
                  <v-checkbox
                    :placeholder="$t('Input template title')"
                    :style="{ width: '100%' }"
                    class="form-checkbox"
                    outlined
                    :label="$t('Fixed Template')"
                    persistent-hint
                    hide-details
                    dense
                    v-model="template.info.fixed">
                  </v-checkbox>
                </div>
                <v-subheader class="sub-title" style="margin-top: 5px;">{{$t('RATIO PREVIEW')}}</v-subheader>
                <v-combobox
                  v-model="templateSize"
                  :items="comboitems2"
                  class="form-combobox"
                  hide-details
                  outlined
                  dense
                  >
                </v-combobox>
                <div class="ratio-preview mt-1">
                  <div class="d-flex time-slider sm-line" :style="{ width: ratio.width + 'px', height: ratio.height + 'px', border: '2px #031e37 solid !important' }">
                    <div class="timecell-event" :style="{ width: previewSize.width, height: previewSize.height }"></div>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
            <!-- CONTENTS Tab Area -->
            <v-tab-item class="custom-scrollbar">
              <v-card flat class="card-contents">
                <div class="form-group" style="display: block;">
                  <label>{{$t('Contents List')}}</label>
                    <v-data-table
                      :headers="templateHeaders"
                      :items="contentsList"
                      class="gray-data-table border-table"
                      height="121"
                      hide-default-footer
                      hide-default-header>
                    <!-- <v-data-table
                      :headers="templateHeaders"
                      :items="templateList"
                      class="gray-data-table border-table"
                      height="121"
                      hide-default-footer
                      hide-default-header> -->
                    <template v-slot:body>
                      <!-- <draggable
                        v-model="props.items"
                        @change="handleShapeListChange"
                        v-bind="dragOptions"
                        @start="start"
                        @end="end"
                        item-key="list"
                        tag="tbody">
                          <tr
                            :class="{ 'selectedRow': item.list === selectedContents }"
                            :headers="templateHeaders"
                            :key="index"
                            @click="handleShapeListClick(item)"
                            v-for="(item, index) in props.items">
                          <td>{{ item.list }}</td>
                          </tr>
                      </draggable> -->
                      <SlickList lockAxis="y" v-model="contentsList" tag="tbody" @sort-start="clickEvent($event)" @sort-end="handleShapeListChange" lockToContainerEdges>
                        <SlickItem v-for="(item, index) in contentsList" :index="index" :key="index" tag="tr" :style="{ 'padding': '0 24px' }" :class="{ 'selectedRow': item.id === selectedShapeId }">
                          <td>{{ (index + 1) + '_' + item.shapeType }}</td>
                        </SlickItem>
                      </SlickList>

                    </template>
                  </v-data-table>
                  <v-row no-gutters>
                    <v-spacer />
                    <v-col cols="2">
                      <v-btn icon @click="listMoveUp()"> <v-icon x-small> mdi-arrow-collapse-up </v-icon> </v-btn>
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon @click="listMoveDown()"> <v-icon x-small> mdi-arrow-collapse-down </v-icon> </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <!-- <div class="form-group mb-4">
                  <label>Contents List</label>
                    <v-data-table
                      :headers="templateHeaders"
                      :items="template.shapes"
                      class="gray-data-table border-table"
                      height="121"
                      hide-default-footer
                      hide-default-header>
                    <template v-slot:body="props">
                      <draggable
                        :list="props.items"
                        @change="handleShapeListChange"
                        tag="tbody">
                          <tr
                            :class="{ 'selectedRow': item.list === selectedContents }"
                            :headers="templateHeaders"
                            :key="index"
                            @click="handleShapeListClick(item)"
                            v-for="(item, index) in props.items">
                          <td>{{ item.shapeType }} {{ item.id }}</td>
                          </tr>
                      </draggable>
                    </template>
                  </v-data-table>
                </div> -->
                <!-- Shape 설정 시작 -->
                  <div v-if="selectedShapeType === 'text' || selectedShapeType === 'product'" class="form-group" style="display:block">
                    <div v-if="selectedShapeType === 'product'">
                      <v-row no-gutters>
                        <label>{{$t('Mapping Product Info')}}</label>
                      </v-row>
                      <v-row no-gutters class="mt-2">
                        <product-info
                          :productName="selectProperty.bind"
                        />
                      </v-row>
                    </div>
                    <v-row no-gutters class="mt-2">
                      <label>{{$t('Font')}}</label>
                    </v-row>
                    <!-- font family 설정 -->
                    <v-row no-gutters>
                      <v-select
                        :items="template.fontConfig.family"
                        @change="changeProperty($event, 'fontFamily')"
                        class="form-select font-select"
                        placeholder="Font Family"
                        hide-details
                        persistent-hint
                        outlined
                        dense
                        v-model="selectProperty.fontFamily" />
                    </v-row>
                    <v-row no-gutters class="mt-2">
                      <v-row no-gutters>
                        <!-- font size 설정 -->
                        <v-col cols="4">
                          <v-text-field
                            type="number"
                            @input="changeProperty($event, 'fontSize')"
                            class="font-center"
                            placeholder="Font Size"
                            hide-details
                            persistent-hint
                            outlined
                            dense
                            v-model="selectProperty.fontSize" />
                        </v-col>
                        <v-col cols="8">
                          <v-select
                            :items="template.fontConfig.style"
                            @change="changeProperty($event, 'fontStyle')"
                            class="form-select font-select ml-6"
                            placeholder="Font Style"
                            hide-details
                            persistent-hint
                            outlined
                            dense
                            v-model="selectProperty.fontStyle" />
                        </v-col>
                      </v-row>
                    </v-row>
                    <v-row no-gutters class="mt-2">
                      <v-col cols="4">
                          <!-- @blur="handleShapeConfigBlur('kerning')" -->
                          <!-- v-model="template.shapeConfig.font.kerning" -->
                        <v-text-field
                          type="number"
                          @input="changeProperty($event, 'letterSpacing')"
                          class="form-select font-center"
                          placeholder="spacing"
                          prepend-icon="mdi-magnify"
                          hide-details
                          persistent-hint
                          outlined
                          dense
                          v-model="selectProperty.letterSpacing"
                        >
                        <template v-slot:prepend>
                          <img src="@/assets/img/ico_text_indent.png"/>
                        </template>
                        </v-text-field>
                      </v-col>
                          <!-- @blur="handleShapeConfigBlur('lineHeight')" -->
                          <!-- v-model="template.shapeConfig.font.lineHeight" -->
                      <!-- <v-col cols="3">
                        <v-text-field
                          type="number"
                          @input="changeProperty($event, 'lineHeight')"
                          class="font-center ml-2"
                          placeholder="행간"
                          prepend-icon="mdi-magnify"
                          v-model="selectProperty.lineHeight"
                        >
                        <template v-slot:prepend>
                          <img src="@/assets/img/ico_text_line_indent.png"/>
                        </template>
                        </v-text-field>
                      </v-col> -->
                      <v-spacer />
                      <v-col cols="5">
                        <v-row>
                          <v-col cols="3">
                            <v-btn icon class="ml-2" @click="changeProperty($event, 'alignLeft')">
                              <img src="@/assets/img/ico_left_align.png"/>
                            </v-btn>
                          </v-col>
                          <v-col cols="3">
                            <v-btn icon class="ml-2" @click="changeProperty($event, 'alignCenter')">
                              <img src="@/assets/img/ico_center_align.png"/>
                            </v-btn>
                          </v-col>
                          <v-col cols="3">
                            <v-btn icon class="ml-2" @click="changeProperty($event, 'alignRight')">
                              <img src="@/assets/img/ico_right_align.png"/>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <div v-if="selectedShapeType === 'text'" class="form-group mb-4 mt-2" style="width:100%">
                      <!-- <label>Text</label> -->
                      <!-- TODO: 텍스트 체인지 -->
                      <v-text-field
                        :placeholder="$t('Input text')"
                        :label="$t('Text')"
                        @input="changeProperty($event, 'text')"
                        class="form-input"
                        clearable
                        dense
                        hide-details
                        outlined
                        v-model="selectProperty.text">
                      </v-text-field>
                    </div>
                  </div>

                  <div class="form-group">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <label>{{$t('Size')}}</label>
                        <v-row no-gutters class="align-center mt-3">
                          <v-col cols="2">
                            <label>W</label>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              type="number"
                              @input="changeProperty($event, 'width')"
                              class="form-group text-center mr-2"
                              hide-details
                              outlined
                              dense
                              ref="shapeWidth"
                              single-line
                              :disabled="selectedShapeId === 0"
                              v-model="selectProperty.width">
                            <!-- <v-text-field
                              type="number"
                              :style="{ width: '80%' }"
                              @input="handleShapeConfigBlur('width')"
                              class="text-center"
                              hide-details
                              ref="shapeWidth"
                              single-line
                              v-model="template.shapeConfig.width"> -->
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <label>H</label>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              type="number"
                              @input="changeProperty($event, 'height')"
                              class="text-center mr-2"
                              hide-details
                              dense
                              outlined
                              ref="shapeHeight"
                              single-line
                              :disabled="selectedShapeId === 0"
                              v-model="selectProperty.height">
                            </v-text-field>
                            <!-- <v-text-field
                              type="number"
                              :style="{ width: '80%' }"
                              @input="handleShapeConfigBlur('height')"
                              class="text-center"
                              hide-details
                              ref="shapeHeight"
                              single-line
                              v-model="template.shapeConfig.height">
                            </v-text-field> -->
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="align-center mt-2">
                          <v-col cols="2">
                            <label>X</label>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              type="number"
                              @input="changeProperty($event, 'x')"
                              class="text-center mr-2"
                              hide-details
                              outlined
                              dense
                              ref="shapeX"
                              single-line
                              :disabled="selectedShapeId === 0"
                              v-model="selectProperty.x">
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <label>Y</label>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              type="number"
                              @input="changeProperty($event, 'y')"
                              class="text-center mr-2"
                              hide-details
                              outlined
                              dense
                              ref="shapeY"
                              single-line
                              :disabled="selectedShapeId === 0"
                              v-model="selectProperty.y">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" class="mt-4">
                        <label>{{$t('Option')}}</label>
                        <v-row no-gutters class="align-center mt-2">
                          <v-col cols="6">
                            <label>{{$t('Opacity')}}</label>
                          </v-col>
                          <v-col cols="6">
                            <!-- <v-combobox
                              :items="template.shapeConfig.opacity.items"
                              :placeholder="$t('Newton touch 23')"
                              @change="handleShapeConfigBlur('opacity')"
                              class="form-combobox"
                              hide-details
                              outlined
                              ref="shapeOpacity"
                              solo
                              v-model="template.shapeConfig.opacity.value">
                            </v-combobox> -->
                            <v-text-field
                              type="number"
                              @input="changeProperty($event, 'opacity')"
                              class="text-center"
                              hide-details
                              outlined
                              dense
                              ref="shapeOpacity"
                              single-line
                              min="0"
                              max="100"
                              :disabled="selectedShapeId === 0"
                              v-model="selectProperty._opacity">
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row no-gutters class="align-center mt-4">
                          <v-col cols="6">
                            <label>{{$t('Rotate')}}</label>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              type="number"
                              @input="changeProperty($event, 'rotate')"
                              class="text-center"
                              hide-details
                              dense
                              outlined
                              ref="shapeRotate"
                              single-line
                              :disabled="selectedShapeId === 0"
                              v-model="selectProperty.rotation">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="form-group mt-4" style="display:block" v-if="selectProperty.shapeType === 'product' || selectProperty.shapeType === 'text'">
                    <v-row no-gutters>
                      <v-col cols="4"> <v-checkbox label="Fit" v-model="selectProperty.fit" @change="changeProperty($event, 'fit')" hide-details/> </v-col>
                      <v-col cols="4" style="margin-top: 16px">
                        <v-select label="Wrap" class="form-select" :disabled="selectProperty.fit" :items="['word', 'char']" v-model="selectProperty.wrap" @change="changeProperty($event, 'wrap')" outlined dense hide-details/>
                      </v-col>
                      <v-col cols="4" style="margin-top: 16px">
                        <v-text-field label="Line"  class="ml-2 form-input" type="number" :disabled="selectProperty.fit" v-model.number="selectProperty.line" @change="changeProperty($event, 'line')" outlined dense hide-details/>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="form-group mt-4" style="display:block" v-if="selectProperty.shapeType !== 'image'">
                    <v-row no-gutters class="align-center mt-3" v-show="selectProperty.shapeType !== 'line'">
                      <!-- <v-col cols="1" class="mr-3">
                        <v-checkbox
                          value
                          hide-details
                          class="pt-0 mt-0 ripple-opacity0"
                        ></v-checkbox>
                      </v-col> -->
                      <v-col cols="12">
                        <label>{{$t('Shape')}}</label>
                      </v-col>
                      <v-col cols="2" class="mt-4">
                        <label>{{$t('Fill')}}</label>
                      </v-col>
                      <v-col cols="4" class="mt-4">
                        <center>
                          <v-menu offset-y
                            :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :disabled="selectedShapeId === 0"
                                :color="selectProperty.fill"
                                class="color-control"
                                dark
                                small
                                v-on="on">
                              </v-btn>
                            </template>
                            <v-color-picker
                              class="mx-auto"
                              flat
                              @update:color="changeProperty($event, 'fill')"
                              v-model="selectProperty.fill">
                            </v-color-picker>
                          </v-menu>
                        </center>
                      </v-col>
                      <!-- <v-col cols="2">
                        <div class="d-flex justify-end">
                          <v-icon>mdi-eyedropper-variant</v-icon>
                        </div>
                      </v-col> -->
                      <v-col cols="2" class="mt-4">
                        <label>{{$t('Stroke')}}</label>
                      </v-col>
                      <v-col cols="4" class="mt-4">
                        <center>
                          <v-menu offset-y
                            :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :disabled="selectedShapeId === 0"
                                :color="selectProperty.stroke"
                                class="color-control"
                                dark
                                small
                                v-on="on">
                              </v-btn>
                            </template>
                            <v-color-picker
                              class="mx-auto"
                              flat
                              @update:color="changeProperty($event, 'stroke')"
                              v-model="selectProperty.stroke">
                            </v-color-picker>
                          </v-menu>
                        </center>
                      </v-col>
                    </v-row>

                    <!-- <v-row no-gutters class="align-center mt-3" v-if="selectProperty.shapeType !== 'image'"> -->
                      <!-- <v-col cols="1" class="mr-3">
                        <v-checkbox
                          class="pt-0 mt-0 ripple-opacity0"
                          hide-details
                          value>
                        </v-checkbox>
                      </v-col> -->
                      <!-- <v-col cols="4">
                        <v-menu offset-y
                          :close-on-content-click="false">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :disabled="selectedShapeId === 0"
                              :color="selectProperty.stroke"
                              class="color-control"
                              dark
                              small
                              v-on="on">
                            </v-btn>
                          </template>
                          <v-color-picker
                            class="mx-auto"
                            flat
                            @update:color="changeProperty($event, 'stroke')"
                            v-model="selectProperty.stroke">
                          </v-color-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="4">
                        <label>Stroke</label>
                      </v-col>
                      <v-col cols="2">
                        <div class="d-flex justify-end">
                          <v-icon>mdi-eyedropper-variant</v-icon>
                        </div>
                      </v-col>
                    </v-row> -->

                    <v-row no-gutters class="align-center mt-3" v-if="selectProperty.shapeType !== 'image'">
                      <v-col cols="12">
                        <v-row no-gutters class="align-center">
                          <v-col cols="5">
                            <label>{{$t('Stroke Width')}}</label>
                          </v-col>
                          <v-col cols="7">
                            <v-text-field
                              :disabled="selectedShapeId === 0"
                              type="number"
                              :style="{ width: '80%' }"
                              @input="changeProperty($event, 'strokeWidth')"
                              class="text-center ml-3"
                              hide-details
                              outlined
                              dense
                              single-line
                              v-model="selectProperty.strokeWidth">
                            </v-text-field>
                          </v-col>
                          <!-- <v-col cols="2">
                            <label>Dash</label>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field
                              type="number"
                              :style="{ width: '80%' }"
                              @input="changeProperty($event, 'dash')"
                              class="text-center"
                              hide-details
                              single-line
                              v-model="selectProperty.dash">
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <label>Space</label>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field
                              type="number"
                              :style="{ width: '80%' }"
                              @input="changeProperty($event, 'space')"
                              class="text-center"
                              hide-details
                              single-line
                              v-model="selectProperty.dash">
                            </v-text-field>
                          </v-col> -->
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                <!-- Shape 설정 끝 -->
              </v-card>
            </v-tab-item>
            <v-tab-item style="height: 700px">
              <v-card flat class="card-animation">
                <div class="form-group mb-4 col" style="padding:0;display: flow-root;">
                  <!-- <label>Action Time(s)</label> -->
                  <div class="d-flex align-center">
                    <v-combobox
                      :items="template.animation.actionTime.list"
                      :placeholder="$t('Direct Input')"
                      @change="handleActionTimeChange"
                      class="form-select"
                      :label="$t('Action Time(s)')"
                      hide-details
                      outlined
                      dense
                      v-model="template.animation.actionTime.selected">
                    </v-combobox>
                    <v-text-field
                      :placeholder="$t('Action Times')"
                      @blur="handleActionTimeDirectInputBlur"
                      class="form-input ml-3"
                      clearable
                      dense
                      hide-details
                      outlined
                      v-if="template.animation.actionTime.selected.text === 'Direct Input'"
                      v-model="template.animation.actionTime.selected.value">
                    </v-text-field>
                  </div>
                </div>
                <div class="form-group col mb-3 mt-4" style="padding:0">
                  <!-- <label>Action Effect</label> -->
                  <v-combobox
                    :items="template.animation.actionEffect.list"
                    :placeholder="$t('None')"
                    class="form-select"
                    hide-details
                    outlined
                    :label="$t('Action Effect')"
                    dense
                    v-model="template.animation.actionEffect.selected">
                  </v-combobox>
                </div>
                <div class="form-group mb-2" style="width:100%">
                  <label class="label-txt">{{$t('Action Shape')}}</label>
                </div>
                <div class="form-group mb-4" style="width:100%">
                  <v-btn
                    :style="{ width: '49%' }"
                    text
                    class="btn"
                    @click="openStartAnimation"
                    >{{ $t("Start") }}
                  </v-btn>
                  <v-btn
                    :style="{ width: '49%' }"
                    text
                    class="btn ml-3"
                    @click="openEndAnimation"
                    >{{ $t("Ending") }}
                  </v-btn>
                </div>
                <div class="form-group mb-4 mt-4">
                  <!-- <label>Holding Time</label> -->
                  <div class="d-flex align-center" style="width:100%">
                    <v-text-field
                      :placeholder="$t('10')"
                      class="form-input"
                      clearable
                      dense
                      :label="$t('Holding Time')"
                      hide-details
                      outlined
                      v-model="template.animation.holdingTime">
                    </v-text-field>
                    <span class="ml-3 caption">{{$t('seconds')}}</span>
                  </div>
                </div>
                <div class="form-group mb-4 mt-4" style="width:100%">
                  <!-- <label>Trigger</label> -->
                  <v-combobox
                    :items="template.animation.trigger.list"
                    :placeholder="$t('Another Template')"
                    class="form-select"
                    hide-details
                    :label="$t('Trigger')"
                    outlined
                    dense
                    @change="handleTriggerChange"
                    v-model="template.animation.trigger.selected">
                  </v-combobox>
                  <trigger-dialog
                    :triggerTemplate="template.animation.trigger.item"
                    v-if="template.animation.trigger.selected === 'Another Template'"
                   />
                </div>
                <div class="form-group mb-2 mt-4" style="width:100%;display: flow-root;">
                  <!-- <label>Return to Trigger</label> -->
                  <div class="d-flex align-center">
                    <v-combobox
                      :items="template.animation.returnToTrigger.list"
                      :placeholder="$t('Direct Input')"
                      class="form-select"
                      :label="$t('Return to Trigger')"
                      hide-details
                      outlined
                      dense
                      v-model="template.animation.returnToTrigger.selected">
                    </v-combobox>
                    <v-text-field
                      class="ml-3 text-center"
                      hide-details
                      single-line
                      v-if="template.animation.returnToTrigger.selected === 'Time (Direct Input)'"
                      value="0">
                    </v-text-field>
                  </div>
                </div>

                <v-subheader class="sub-title" style="margin-top: 30px !important;">{{$t('PREVIEW')}}</v-subheader>
                <div>
                  <div class="ratio-preview mt-1 mb-6" style="overflow: hidden;">
                    <div style="width: 210px; height: 130px; align-self: center;">
                      <div style="width: 210px; height: 130px; position: absolute; display: flex; justify-content: center; align-self: center; margin-right: 25px; cursor: pointer;" @click="previewClick">
                        <v-icon :style="{ display: animationStatus.start || animationStatus.end ? 'none' : '' }">mdi-play-circle-outline</v-icon>
                      </div>
                      <div :class="getAnimationState()" :style=" { 'background-image': 'url(' + previewImage + ')', 'background-size': previewWidth+'px' + ' ' + previewHeight+'px', width: previewImage.width, height: previewImage.height, 'margin-left': Number((210-previewWidth) / 2) + 'px', 'margin-top': Number((130-previewHeight) / 2) + 'px' } ">
                        <div style="display: flex; justify-content: space-around; height: 130px;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <br>
          <div class="col-12">
            <div class="row">
              <div class="col-5">
            <v-btn
            text
            class="btn d-block width-full"
            style="width:100px"
            @click="saveAnimation"
            >{{ $t("Save") }}</v-btn
          >
        </div>
        <div style="width:150px" class="col-5">
          <v-btn
            text
            class="btn d-block ml-2 width-full"
            style="width:100px"
            @click="cancelAnimation"
            >{{ $t("Cancel") }}</v-btn>
          </div>
        </div>
        </div>
        </v-card>
      </aside>
    </div>

    <!-- Start Animation -->
    <v-dialog v-model="startDialog" width="650" class="rounded-0" @click:outside="startAnimationClose">
      <v-card class="popup add_store_popup">
        <h3 class="page-title-bar">
          <i class="ico ico-template"></i>
          {{ $t("Start Animation") }}
        </h3>
        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col cols="2">
              <div class="form-group mb-4">
                <label>{{$t('Opacity')}}</label>
                <div class="d-flex align-center">
                  <v-text-field
                    @input="inputNumber($event, 'startOpacity')"
                    class="form-input"
                    counter
                    maxlength="3"
                    dense
                    hide-details
                    outlined
                    v-model="template.animation.start.opacity">
                  </v-text-field>
                  <span class="px-3">%</span>
                </div>
              </div>
            </v-col>
            <v-col cols="4" class="ml-5">
              <div class="form-group mb-4">
                <label>{{$t('Location')}}</label>
                <div class="d-flex align-center">
                  <span class="pr-3">X</span>
                  <v-text-field
                    type="number"
                    class="form-input"
                    dense
                    counter
                    maxlength="5"
                    hide-details
                    outlined
                    v-model="template.animation.start.x">
                  </v-text-field>
                  <span class="pl-5 pr-3">Y</span>
                  <v-text-field
                    type="number"
                    class="form-input"
                    dense
                    counter
                    maxlength="4"
                    hide-details
                    outlined
                    v-model="template.animation.start.y">
                  </v-text-field>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols="4" class="ml-5">
              <div class="form-group mb-4">
                <label>{{$t('Mask')}}</label>
                <div class="d-flex align-center">
                  <span class="pr-3">W</span>
                  <v-text-field
                    class="form-input"
                    counter
                    maxlength="4"
                    dense
                    hide-details
                    outlined
                    v-model.number="animationData.start.width">
                  </v-text-field>
                  <span class="pl-5 pr-3">H</span>
                  <v-text-field
                    class="form-input"
                    counter
                    maxlength="4"
                    dense
                    hide-details
                    outlined
                    v-model.number="animationData.start.height">
                  </v-text-field>
                </div>
              </div>
            </v-col> -->
          </v-row>
          <h6 class="caption text-left pb-2">{{$t('Location')}}</h6>
          <div class="selected-template-box">
            <div class="d-flex flex-column ml-3">
              <v-btn class="btn-white" @click="startIncrement">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn class="btn-white mt-2" @click="startDecrement">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </div>
            <perfect-scrollbar :style="{ width: '468px', height: '224px' }" ref="sps">
              <div style="text-align: center; margin: 50px; padding-left: 50px; width: 2000px;">
                <div class="animation-preview" :style="{ transform: 'scale(' + actionShape.startScale + ')', 'transform-origin': 'left top' }">
                  <div style="display: flex; outline: solid 10px black; margin 10px;" :style="{ width: 1920 + 'px', height: (templateSize.value === 23 ? 158 : 540) + 'px' }">
                    <div :style="{
                      position: 'relative',
                      'background-image': 'url(' + animationImage + ')',
                      'background-size': template.info.width+'px' + ' ' + template.info.height+'px',
                      'margin-left': template.animation.start.x + 'px',
                      'margin-top': template.animation.start.y + 'px',
                      width: template.info.width + 'px',
                      height: template.info.height + 'px',
                      'opacity': Number(template.animation.start.opacity / 100) } ">
                    </div>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <!-- <v-btn text icon class="btn" @click="saveStartAnimation">{{$t("Save")}}</v-btn> -->
          <v-btn text icon @click="startAnimationSave" class="btn">{{$t("Save")}}</v-btn>
          <v-btn text icon @click="startAnimationClose" class="btn">{{$t("Cancel")}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- End Animation -->
    <v-dialog v-model="endingDialog" width="650" class="rounded-0" @click:outside="endAnimationClose">
      <v-card class="popup add_store_popup">
        <h3 class="page-title-bar">
          <i class="ico ico-template"></i>{{ $t("Ending Animation") }}
        </h3>
        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col cols="2">
              <div class="form-group mb-4">
                <label>{{$t('Opacity')}}</label>
                <div class="d-flex align-center">
                  <v-text-field
                    @input="inputNumber($event, 'endOpacity')"
                    class="form-input"
                    counter
                    maxlength="3"
                    dense
                    hide-details
                    outlined
                    v-model="template.animation.end.opacity">
                  </v-text-field>
                  <span class="px-3">%</span>
                </div>
              </div>
            </v-col>
            <v-col cols="4" class="ml-5">
              <div class="form-group mb-4">
                <label>{{$t('Location')}}</label>
                <div class="d-flex align-center">
                  <span class="pr-3">X</span>
                  <v-text-field
                    type="number"
                    class="form-input"
                    dense
                    hide-details
                    outlined
                    v-model="template.animation.end.x">
                  </v-text-field>
                  <span class="pl-5 pr-3">Y</span>
                  <v-text-field
                    type="number"
                    class="form-input"
                    dense
                    hide-details
                    outlined
                    v-model="template.animation.end.y">
                  </v-text-field>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols="4" class="ml-5">
              <div class="form-group mb-4">
                <label>{{$t('Mask')}}</label>
                <div class="d-flex align-center">
                  <span class="pr-3">W</span>
                  <v-text-field
                    class="form-input"
                    counter
                    maxlength="4"
                    dense
                    hide-details
                    outlined
                    v-model.number="animationData.end.width">
                  </v-text-field>
                  <span class="pl-5 pr-3">H</span>
                  <v-text-field
                    class="form-input"
                    counter
                    maxlength="4"
                    dense
                    hide-details
                    outlined
                    v-model.number="animationData.end.height">
                  </v-text-field>
                </div>
              </div>
            </v-col> -->
          </v-row>
          <h6 class="caption text-left pb-2">{{$t('Location')}}</h6>
          <div class="selected-template-box">
            <div class="d-flex flex-column ml-3">
              <v-btn class="btn-white" @click="endingIncrement">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn class="btn-white mt-2" @click="endingDecrement">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </div>
            <perfect-scrollbar :style="{ width: '468px', height: '224px' }" ref="eps">
              <div style="text-align: center; margin: 50px; padding-left: 50px; width: 2000px;">
                <div class="animation-preview" :style="{ transform: 'scale(' + actionShape.endScale + ')', 'transform-origin': 'left top' }">
                  <div style="display: flex; outline: solid 10px black; margin 10px;" :style="{ width: 1920 + 'px', height: (templateSize.value === 23 ? 158 : 540) + 'px' }">
                    <div :style="{
                      position: 'relative',
                      'background-image': 'url(' + animationImage + ')',
                      'background-size': template.info.width+'px' + ' ' + template.info.height+'px',
                      'margin-left': template.animation.end.x + 'px',
                      'margin-top': template.animation.end.y + 'px',
                      width: template.info.width + 'px',
                      height: template.info.height + 'px',
                      'opacity': Number(template.animation.end.opacity / 100) } ">
                    </div>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <!-- <v-btn text icon class="btn" @click="saveEndingAnimation">{{ $t("Save") }}</v-btn> -->
          <v-btn text icon @click="endAnimationSave" class="btn">{{ $t("Save") }}</v-btn>
          <v-btn text icon @click="endAnimationClose" class="btn">{{ $t("Cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <get-template
      :getTemplateDialog="templateDialog"
    />
  </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
// import $ from 'jquery'
import EventBus from '@/plugins/eventBus'
import Vue from 'vue'
import VueKonva from 'vue-konva'
import dicamoApi from '@/lcd/plugins/dicamoApi'
import TriggerDialog from './modal/TriggerDialog.vue'
import ProductInfo from './modal/ProductInfo.vue'
import GetTemplate from './modal/GetTemplateItem.vue'
// import draggable from 'vuedraggable'
import { SlickList, SlickItem } from 'vue-slicksort'
import Konva from 'konva'

Vue.use(VueKonva)

export default {
  name: 'EditTemplate',
  components: {
    // draggable,
    TriggerDialog,
    ProductInfo,
    PerfectScrollbar,
    GetTemplate,
    SlickItem,
    SlickList
  },
  props: {
    index: Number
  },
  data () {
    return {
      inputFile: null,
      animationData: {
        start: {
          width: 0,
          height: 0
        },
        end: {
          width: 0,
          height: 0
        }
      },
      animationRatio: {
        width: 0,
        height: 0
      },
      timeout: null,
      templateSize: {
        text: 'Newton touch 23',
        value: 23
      },
      templateDialog: false,
      actionShape: {
        startScale: 1.0,
        endScale: 1.0
      },
      animationImage: '',
      animationStatus: {
        start: false,
        end: false
      },
      ruler: {
        big: [0, 100, 200, 300, 400, 500, 600],
        item: [],
        max: 600,
        min: 0
      },
      selectedContents: '',
      selectedShapeType: '',
      selectedIsProduct: false,
      // 추가
      template_data: {
        info: {
          title: '',
          width: '',
          height: '',
          opacity: 1,
          layer: 1
        }
      },
      contentsList: [],
      selectedShape: null,

      // isProduct: false,
      default: {
        fill: '#ffffff',
        stroke: {
          color: '#5d5d5d',
          dash: 0,
          size: 0.3,
          space: 0
        }
      },
      template: {
        info: {
          height: 158,
          layer: {
            items: [5, 4, 3, 2, 1],
            value: 1
          },
          opacity: {
            items: [1.0, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0],
            value: 1
          },
          fixed: false,
          title: '',
          width: 400
        },
        shapeConfig: {
          bind: '',
          fill: '#ffffff',
          font: {
            align: 'left',
            family: 'sans-serif',
            kerning: 0,
            lineHeight: 0,
            size: 12,
            style: 'normal'
          },
          height: 50,
          isProduct: false,
          opacity: {
            items: [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1],
            value: 1
          },
          rotate: 0,
          stroke: {
            color: '#5d5d5d',
            dash: 0,
            size: 3,
            space: 0
          },
          text: '',
          width: 50,
          x: 0,
          y: 0
        },
        fontConfig: {
          align: ['left', 'center', 'right'],
          family: ['monospace', 'casual', 'cursive', 'sans-serif', 'sans-serif-black', 'sans-serif-condensed', 'sans-serif-condensed-light', 'sans-serif-condensed-medium', 'sans-serif-light', 'sans-serif-medium', 'sans-serif-smallcaps', 'sans-serif-thin', 'serif', 'serif-monospace'],
          kerning: 0,
          lineHeight: 0,
          size: 12,
          style: ['normal', 'bold', 'italic']
        },
        animation: {
          actionEffect: {
            list: ['None', 'Ease Out', 'Ease In', 'Ease In-Out', 'Snap', 'Wind Up', 'Bounce'],
            selected: 'None'
          },
          actionTime: {
            list: [
              { text: 'Very Slow (3)', value: 3000 },
              { text: 'Slow (1.5)', value: 1500 },
              { text: 'Normal (0.6)', value: 600 },
              { text: 'Fast (0.3)', value: 300 },
              { text: 'Very Fast (0.1)', value: 100 }
              // { text: 'Direct Input', value: 0 }
            ],
            selected: { text: 'Normal (0.6)', value: 600 }
          },
          end: { height: 0, opacity: 100, width: 0, x: 0, y: 0 },
          holdingTime: 0,
          returnToTrigger: {
            list: [
              'Immediately', 'Touch Again'
            ],
            selected: 'Immediately'
          },
          start: { height: 0, opacity: 100, width: 0, x: 0, y: 0 },
          trigger: {
            list: [
              'Self',
              'Another Template'
            ],
            selected: 'Self',
            item: {}
          }
        },
        shapeConfigTemp: {},
        shapes: [],
        selectedId: ''
      },
      scale: 1,
      comboitems2: [
        {
          text: 'Newton Touch 23"',
          value: 23
        },
        {
          text: 'Newton Touch 29"',
          value: 29
        },
        {
          text: 'FHD Box',
          value: 'FHD'
        },
        {
          text: 'Tablet',
          value: 'Tablet'
        }],
      page: 0,
      color: '#ff7000',
      unitOption: ['mm', 'cm', 'px', 'inch'],
      tab: null,
      startDialog: false,
      endingDialog: false,
      templateHeaders: [
        { text: '', align: 'left', sortable: false, value: 'shapeType' }
      ],
      templateList: [],
      configKonva: {
        width: 400,
        height: 158
      },
      previewImage: '',
      isSaveClicked: false,
      templateData: [],
      mouse: {
        isDown: false,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0
      },
      clipboard: [],
      history: [],
      historyIndex: 0
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isSaveClicked) {
      next()
    } else {
      this.$confirm(this.$t('Are you sure you want to leave this page? \n Changes may not be saved.')).then(() => {
        next()
      })
    }
  },
  beforeUnmount () {
    window.removeEventListener('beforeunload', this.unloadEvent)
    window.removeEventListener('keydown', this.keyEvent)
    window.removeEventListener('mouseup', this.mouseEvent)
  },
  mounted: function () {
    /**
     * TODO: COMPANY가 DOU(Douglas)일 때 font 추가.
     */
    if (this.$store.state.auth.user.company === 'DICAMO') this.template.fontConfig.family.push('douglas-regular', 'douglas-bold', 'douglas-demibold', 'douglas-ultralight')
    if (this.$store.state.auth.user.company === 'DOU') this.template.fontConfig.family.push('douglas-regular', 'douglas-bold', 'douglas-demibold', 'douglas-ultralight')
    const index = window.sessionStorage.getItem('template_index')
    this.getTemplateData(index)
    this.blurTemplateConfig('width')
    this.handleTemplateSizeChanged()
    if (this.ruler.item.length === 0) {
      var i = 0
      for (i = 0; i < this.ruler.max; i = i + 10) {
        var temp = ((i / 100) === Math.round(i / 100))
        this.ruler.item.push({
          pixel: i,
          isBig: temp,
          isGraduation: true
        })
      }
    }
    EventBus.$on('mappingProductInfo', (text) => {
      this.template.shapeConfig.bind = text
      const item = this.contentsList.find((r) => {
        if (r.id === this.selectedShapeId) return true
      })
      item.bind = text
      item.text = text
      this.changeProperty(text, 'text')
    })
    EventBus.$on('mappingTrigger', (item) => {
      this.template.animation.trigger.item = item
    })
    EventBus.$on('templateDataPush', (item) => {
      this.getTemplateData(item)
    })
    EventBus.$on('fireResetTemplateDialog', () => {
      this.templateDialog = false
    })
    window.addEventListener('beforeunload', this.unloadEvent)
    window.addEventListener('keydown', this.keyEvent)
    window.addEventListener('mouseup', this.mouseEvent)
  },
  updated: function () {
    // this.consolelog('updated')
  },
  methods: {
    listMoveUp () {
      const shape = this.contentsList.find(r => {
        if (r.id === this.selectedShapeId) return true
      })
      const index = this.contentsList.findIndex(r => {
        if (r.id === this.selectedShapeId) return true
      })
      if (shape.shapeType === 'image') {
        // console.log('image')
        this.contentsList.unshift(shape)
        this.contentsList.splice(index + 1, 1)
      } else {
        const item = JSON.stringify(shape)
        this.contentsList.splice(index, 1)
        this.contentsList.unshift(JSON.parse(item))
      }
    },
    listMoveDown () {
      const shape = this.contentsList.find(r => {
        if (r.id === this.selectedShapeId) return true
      })
      const index = this.contentsList.findIndex(r => {
        if (r.id === this.selectedShapeId) return true
      })
      if (shape.shapeType === 'image') {
        this.contentsList.push(shape)
        this.contentsList.splice(index, 1)
        // console.log('image')
      } else {
        const item = JSON.stringify(shape)
        this.contentsList.splice(index, 1)
        this.contentsList.push(JSON.parse(item))
      }
    },
    handleShapeListChange (e) {
      if (e.oldIndex === e.newIndex) {

      } else {
        this.updateTransformer()
      }
    },
    rotatePoint ({ x, y }, rad) {
      const rcos = Math.cos(rad)
      const rsin = Math.sin(rad)
      return { x: x * rcos - y * rsin, y: y * rcos + x * rsin }
    },
    changeProperty (e, type) {
      if (type === 'c_width') {
        this.template.info.width = e
        if (Number(e) < 0) this.$set(this.template.info, 'width', 1)
        this.setScale()
        return
      }
      if (type === 'c_height') {
        this.template.info.height = e
        if (Number(e) < 0) this.$set(this.template.info, 'height', 1)
        this.setScale()
        return
      }
      const item = this.contentsList.find(r => {
        if (r.id === this.selectedShape.attrs.id) return true
      })
      const shapeItem = this.selectedShape

      const topLeft = { x: -item.width / 2, y: -item.height / 2 }
      // const topLeft = { x: 0, y: 0 }
      const current = this.rotatePoint(topLeft, Konva.getAngle(item.rotation))
      const rotated = this.rotatePoint(topLeft, Konva.getAngle(Number(e)))
      const dx = rotated.x - current.x
      const dy = rotated.y - current.y

      // console.log(topLeft)
      if (Number(e) < 0) e = 1

      item.offsetX = Number(0)
      item.offsetY = Number(0)
      if (type === 'width' || type === 'height') {
        if (Number(e) === 0) e += 1
      }
      switch (type) {
        case 'text':
          item.text = String(e)
          if (item.fit) {
            const width = item.width * item.scaleX
            const textItem = this.selectedShape.measureSize(e)
            if (width > textItem.width) return
            const ratio = item._width / textItem.width
            // console.log(ratio)
            item.scaleX = ratio
            item.width = Math.ceil(textItem.width)
            // console.log(item)
          }
          break
        case 'width':
          if (item.shapeType === 'text' || item.shapeType === 'product') {
            let width = item.width * item.scaleX
            if (item.shapeType === 'text' || item.shapeType === 'product') width = Number(this.selectProperty.width)
            const text = item.shapeType === 'text' ? item.text + ' ' : item.bind + ' '
            const textItem = this.selectedShape.measureSize(text)
            console.log(`Text : ${item.text} Bind: ${item.bind} Width: ${width} testWidth: ${textItem.width}`)
            if (item.fit && width < textItem.width) {
              item._width = Number(e)
              item.scaleX = Number(Number(e) / item.width)
            } else {
              item.width = Number(e)
              item._width = Number(e)
              item.scaleX = Number(1)
            }
          } else {
            item._width = Number(e)
            item.scaleX = Number(Number(e) / item.width)
          }
          break
        case 'height':
          if (item.shapeType === 'text' || item.shapeType === 'product') {
            item.height = Number(e)
            item._height = Number(e)
            item.scaleY = Number(1)
          } else {
            item._height = Number(e)
            item.scaleY = Number(Number(e) / item.height)
          }
          break
        case 'x':
          item.x = item.shapeType === 'triangle' || item.shapeType === 'ellipse' ? Number(e) + Number(item._width / 2) : Number(e)
          item._x = Number(e)
          break
        case 'y':
          item.y = item.shapeType === 'triangle' || item.shapeType === 'ellipse' ? Number(e) + Number(item._height / 2) : Number(e)
          item._y = Number(e)
          break
        case 'opacity':
          item.opacity = Number(Number(e) / 100)
          break
        case 'rotate':
          item.rotation = Number(e)
          item.x = item.x + dx
          item._x = Number(item.x + dx)
          item.y = item.y + dy
          item._y = Number(item.y + dy)
          break
        case 'fill':
          item.fill = e.hex
          break
        case 'stroke':
          item.stroke = e.hex
          break
        case 'strokeWidth':
          item.strokeWidth = Number(e)
          item.hitStrokeWidth = Number(e) + 10
          break
        case 'dash':
          item.dash[0] = Number(e)
          break
        case 'space':
          item.dash[1] = Number(e)
          break
        case 'fontFamily':
          item.fontFamily = e
          break
        case 'fontSize':
          item.fontSize = Number(e)
          // item.height = Number(e)
          item.scaleX = 1
          item.scaleY = 1
          break
        case 'fontStyle':
          item.fontStyle = e
          break
        case 'alignLeft':
          item.align = 'left'
          break
        case 'alignCenter':
          item.align = 'center'
          break
        case 'alignRight':
          item.align = 'right'
          break
        case 'letterSpacing':
          item.letterSpacing = Number(e)
          break
        case 'fit':
          if (!e) {
            item.width = item._width
            // shapeItem.scaleX(1)
            item.scaleX = 1
          } else {
            const textItem = shapeItem.measureSize(shapeItem.text())
            const width = item.width * item.scaleX
            const ratio = item._width / textItem.width

            if (width > textItem.width) {
              item.scaleX = 1
              item.width = Math.ceil(width)
            } else {
              item.scaleX = ratio
              item.width = Math.ceil(textItem.width)

              item.line = 0
            }
            // console.log(ratio)
            // shapeItem.scaleX(ratio)
          }
          item.fit = e
          break
        case 'wrap':
          item.wrap = String(e)
          break
        case 'line':
          item.line = Number(e)
          if (Number(e) === 0) {
          } else {
            if (Number(e) < shapeItem.textArr.length) {
              shapeItem.textArr.splice(Number(e))
            }
          }
          setTimeout(() => {
            shapeItem.text('')
            shapeItem.text(item.text)
          }, 200)
          break
        default:
          break
      }
    },
    inputNumber (value, type) {
      if (value > 100 && type === 'startOpacity') {
        this.template.animation.start.opacity = 100
      }
      if (value > 100 && type === 'endOpacity') {
        this.template.animation.end.opacity = 100
      }
    },
    clickEvent (e) {
      this.handleShapeListClick(this.contentsList[e.index])
    },
    getTemplateData (index) {
      const data = new FormData()
      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('type', 'item')
      data.append('idx_template', index)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getTemplateList.method,
          dicamoApi.requests.getTemplateList.url,
          data
        )
        .then(res => {
          const request = res.data.data

          const rawData = JSON.parse(request.raw_data)
          this.template = rawData
          this.template.info.opacity.value = Number(this.template.info.opacity.value)
          // 템플릿 크기 보정
          this.configKonva.width = Number(rawData.info.width)
          this.configKonva.height = Number(rawData.info.height)

          const list = []

          const shapes = rawData.shapes

          this.$nextTick(() => {
            shapes.forEach((item, index) => {
              item.lienHeight = 1.5
              if (item.shapeType === 'image') {
                const image = new window.Image()
                image.src = item.src
                image.onload = () => {
                  const imageItem = item
                  imageItem.image = image
                  imageItem.src = item.src
                  // list.push(imageItem)
                  list.splice(index, 0, item)
                }
              } else {
                item.x = item._x
                item.y = item._y
                list.push(item)
              }
            })
            this.contentsList = list
            this.setScale()
            this.setInitialize()
          })
        })
        .catch(error => {
          console.debug(error)
        //   this.lcdlist = []
        })
    },
    async setInitialize () {
      const shapeList = await this.$refs.stage.getStage().children[0].children
      this.contentsList.forEach(item => {
        const shapeItem = shapeList.find(r => r.attrs.id === item.id)
        if (item.shapeType === 'product' || item.shapeType === 'text') {
          item.ellipsis = false
          if (!item.fit) {
            item.fit = false
          } else {
            const textItem = shapeItem.measureSize(shapeItem.text() + ' ')
            const ratio = item._width / textItem.width
            // shapeItem.scaleX(ratio)
            if (item.width > textItem.width) {
              item.width = Number(item._width)
              item.scaleX = Number(1)
            } else {
              item.scaleX = ratio
              item.width = Math.ceil(textItem.width)
            }
          }
          if (item.line !== 0) {
            if (Number(item.line) < shapeItem.textArr.length) {
              shapeItem.textArr.splice(Number(item.line))
            }
          }
          // console.log(item.fit)
          if (!item.wrap || item.wrap === 'none') {
            item.wrap = 'word'
          }
          // console.log(item.wrap)
          if (!item.line) {
            item.line = 0
          }
          // console.log(item.line)
        }
      })
    },
    openStartAnimation () {
      // if (Number(this.template.animation.start.width) === 0) this.animationData.start.width = Number(this.configKonva.width)
      // if (Number(this.template.animation.start.height) === 0) this.animationData.start.height = Number(this.configKonva.height)
      this.startDialog = true
      setTimeout(() => {
        this.$refs.sps.update()
      }, 200)
    },
    openEndAnimation () {
      // if (Number(this.template.animation.end.width) === 0) this.animationData.end.width = Number(this.configKonva.width)
      // if (Number(this.template.animation.end.height) === 0) this.animationData.end.height = Number(this.configKonva.height)
      this.endingDialog = true
      setTimeout(() => {
        this.$refs.eps.update()
      }, 200)
    },
    startAnimationClose () {
      // this.animationData.start.width = this.template.animation.start.width
      // this.animationData.start.height = this.template.animation.start.height
      this.actionShape.startScale = 1
      this.startDialog = false
    },
    endAnimationClose () {
      // this.animationData.end.width = this.template.animation.end.width
      // this.animationData.end.height = this.template.animation.end.height
      this.actionShape.endScale = 1
      this.endingDialog = false
    },
    startAnimationSave () {
      // this.template.animation.start.width = this.animationData.start.width
      // this.template.animation.start.height = this.animationData.start.height
      this.actionShape.startScale = 1
      this.startDialog = false
    },
    endAnimationSave () {
      // this.template.animation.end.width = this.animationData.end.width
      // this.template.animation.end.height = this.animationData.end.height
      this.actionShape.endScale = 1
      this.endingDialog = false
    },
    addEllipse () {
      const ellipseItem = {
        rotation: 0,
        opacity: 1,
        closed: true,
        x: 25,
        y: 25,
        _x: 0,
        _y: 0,
        width: 50,
        height: 50,
        _width: 50,
        _height: 50,
        offsetX: 0,
        offsetY: 0,
        fill: '#FFFFFF',
        stroke: '#5d5d5d',
        strokeWidth: 0.3,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
        id: String(new Date().getTime()),
        shapeType: 'ellipse',
        ignoreStroke: true,
        strokeScaleEnabled: false
      }
      this.contentsList.push(ellipseItem)
      // this.appendShape(ellipseItem)
      // this.addHistory()
    },
    addLine () {
      const lineItem = {
        points: [0, 0, 50, 50],
        rotation: 0,
        opacity: 1,
        closed: false,
        x: 0,
        y: 0,
        _x: 0,
        _y: 0,
        width: 50,
        height: 50,
        _width: 50,
        _height: 50,
        fill: '#FFFFFF',
        stroke: '#5d5d5d',
        strokeWidth: 0.3,
        hitStrokeWidth: 13,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
        id: String(new Date().getTime()),
        shapeType: 'line'
      }
      this.contentsList.push(lineItem)
      // this.appendShape(lineItem)
      // this.addHistory()
    },
    addProduct () {
      const productItem = {
        // absolutePoints: [0, 0, 80, 80],
        bind: 'Product',
        align: 'left',
        draggable: true,
        ellipsis: false,
        fill: this.default.fill,
        fontFamily: 'sans-serif',
        fontSize: 25,
        fontStyle: 'normal',
        // height: 30,
        id: String(new Date().getTime()),
        isProduct: true,
        letterSpacing: 0,
        lineHeight: 1.5,
        oldStrokeWidth: 3,
        oldText: '',
        opacity: 1,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        shapeType: 'product',
        stroke: this.default.stroke.color,
        strokeWidth: 3,
        text: 'Product',
        visible: true,
        // width: 50,
        wrap: 'word',
        line: 0,
        fit: true,
        textArr: [],
        width: 100,
        _width: 100,
        height: 37.5,
        _height: 37.5,
        x: 0,
        y: 0,
        _x: 0,
        _y: 0,
        fillAfterStrokeEnabled: true
      }
      this.contentsList.push(productItem)
      // this.appendShape(textItem)
      // this.addHistory()
    },
    addRect () {
      const rectItem = {
        rotation: 0,
        opacity: 1,
        closed: true,
        x: 0,
        y: 0,
        _x: 0,
        _y: 0,
        width: 50,
        height: 50,
        _width: 50,
        _height: 50,
        offsetX: 0,
        offsetY: 0,
        fill: '#FFFFFF',
        stroke: '#5d5d5d',
        strokeWidth: 0.3,
        scaleX: 1,
        scaleY: 1,
        draggable: true,
        id: String(new Date().getTime()),
        shapeType: 'rect',
        ignoreStroke: true,
        strokeScaleEnabled: false
      }
      this.contentsList.push(rectItem)
      // this.appendShape(rectItem)
      // this.addHistory()
    },
    addText () {
      const textItem = {
        // absolutePoints: [0, 0, 80, 80],
        align: 'left',
        draggable: true,
        ellipsis: false,
        fill: this.default.fill,
        fontFamily: 'sans-serif',
        fontSize: 25,
        fontStyle: 'normal',
        id: String(new Date().getTime()),
        isProduct: false,
        letterSpacing: 0,
        lineHeight: 1.5,
        oldStrokeWidth: 3,
        opacity: 1,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        shapeType: 'text',
        stroke: this.default.stroke.color,
        strokeWidth: 3,
        text: 'Text',
        visible: true,
        // width: 50,
        wrap: 'word',
        line: 0,
        fit: true,
        textArr: [],
        width: 45,
        _width: 45,
        height: 37.5,
        _height: 37.5,
        x: 0,
        y: 0,
        _x: 0,
        _y: 0,
        fillAfterStrokeEnabled: true
      }
      this.contentsList.push(textItem)
      // this.appendShape(textItem)
      // this.addHistory()
    },
    addTriangle () {
      const polygonItem = {
        closed: true,
        sides: 3,
        dash: [
          Number(this.default.stroke.dash),
          Number(this.default.stroke.space)
        ],
        // dashEnabled: true,
        draggable: true,
        fill: this.default.fill,
        id: String(new Date().getTime()),
        oldStrokeWidth: 0.3,
        opacity: 1,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        shapeType: 'triangle',
        ignoreStroke: true,
        strokeScaleEnabled: false,
        stroke: this.default.stroke.color,
        strokeWidth: 0.3,
        visible: true,
        width: 50,
        _width: 50,
        height: 50,
        _height: 50,
        x: 25,
        y: 25,
        _x: 0,
        _y: 0
      }
      this.contentsList.push(polygonItem)
      // this.appendShape(polygonItem)
      // this.addHistory()
    },
    alignText (alignType) {
      var transformerNode = this.$refs.transformer.getNode()
      var selectedNodes = transformerNode.getNodes()
      var vm = this
      selectedNodes.forEach(function (item) {
        if (item.getAttr('shapeType') === 'text') {
          var tmpId = item.getAttr('id')
          var tmpIdx = vm.getSahpeIndexViaId(Number(tmpId))
          vm.template.shapes[tmpIdx].align = alignType
        }
      })
    },
    alignVerticalBottom () {
      this.alignShapes('VB')
    },
    alignVerticalCenter () {
      this.alignShapes('VC')
    },
    alignVerticalTop () {
      this.alignShapes('VT')
    },
    blurTemplateConfig () {
      this.setScale()
    },
    cancelAnimation () {
      this.$router.push('/Lcd/Template')
    },
    changeFile () {
      const files = this.inputFile

      const reader = new FileReader()
      const image = new window.Image()

      reader.addEventListener('load', () => {
        image.src = reader.result
      })

      reader.readAsDataURL(files)

      image.onload = (item) => {
        const imgWidth = item.target.width
        const imgHeight = item.target.height
        const width = imgWidth > this.template.info.width ? this.template.info.width : imgWidth
        const height = imgHeight > this.template.info.height ? this.template.info.height : imgHeight
        const imageItem = {
          rotation: 0,
          opacity: 1,
          closed: true,
          x: 0,
          y: 0,
          _x: 0,
          _y: 0,
          width: width,
          height: height,
          _width: width,
          _height: height,
          offsetX: 0,
          offsetY: 0,
          scaleX: 1,
          scaleY: 1,
          image: image,
          src: image.src,
          draggable: true,
          id: String(new Date().getTime()),
          shapeType: 'image',
          ignoreStroke: true,
          strokeScaleEnabled: false
        }

        this.contentsList.push(imageItem)
        this.inputFile = null
      }
    },
    decrement () {
      this.scale = (this.scale / 125) * 100
      this.setScale()
    },
    delShapes () {
      /**
       * 2022년 1월 9일 수정
       * */
      const transformerNodes = this.$refs.transformer.getNode()

      const index = this.contentsList.findIndex(r => {
        if (r.id === this.selectedShapeId) return true
      })

      this.contentsList.splice(index, 1)
      this.selectedShape = null

      // this.clearTransformer()
      transformerNodes.nodes([])
    },
    endingDecrement () {
      this.actionShape.endScale = this.actionShape.endScale * 0.75
      if (Number(this.actionShape.endScale) < 0.5) {
        this.actionShape.endScale = 0.5
      }
    },
    endingIncrement () {
      this.actionShape.endScale = this.actionShape.endScale * 1.25
      if (Number(this.actionShape.endScale) > 2.0) {
        this.actionShape.endScale = 2.0
      }
    },
    getFile () {
      this.$refs.add_image.$refs.input.click()
      // document.all.add_image.click()
    },
    getTemplate () {
      this.templateDialog = true
      // 작업해야함
    },
    handleActionTimeChange () {
      if (this.template.animation.actionTime.selected.text !== 'Direct Input') {
        this.template.animation.actionTime.list.forEach(function (item) {
          if (item.text === 'Direct Input') {
            item.value = 0
          }
        })
      }
    },
    handleActionTimeDirectInputBlur () {
      this.template.animation.actionTime.list.forEach(function (item) {
        if (item.text === 'Direct Input') {
          if (item.value === '') {
            item.value = 0
          }
        }
      })
    },
    handleShapeListClick (e) {
      // console.log(e)
      const transformerNode = this.$refs.transformer.getNode()
      const stage = transformerNode.getStage()

      const selectedNode = stage.findOne(node => {
        if (node.getType() === 'Shape') {
          if (node.attrs.id === e.id) return node
        }
      })

      if (selectedNode !== undefined) {
        this.selectedShape = selectedNode
        this.selectedShapeType = selectedNode.attrs.shapeType
      } else this.selectedShape = null

      if (selectedNode === transformerNode.node()) {
        return
      }

      if (selectedNode) {
        transformerNode.nodes([selectedNode])
      } else {
        transformerNode.nodes([])
      }
    },
    handleStageMouseDown (e) {
      if (e.target === e.target.getStage()) {
        this.selectedId = ''
        this.updateTransformer()
        return
      }

      const clickedOnTransformer = e.target.getParent().className === 'Transformer'
      if (clickedOnTransformer) {
        return
      }

      const id = e.target.id()
      const shape = this.contentsList.find((r) => r.id === id)
      if (shape) {
        this.selectedShape = id
      } else {
        this.selectedShape = ''
      }
      // console.log('success')
      this.updateTransformer()
    },

    handleTemplateSizeChanged () {
      this.setScale()
    },
    handleDragEnd (e) {
      // console.log(e.target.x())
      // console.log(e.target._x())
      const item = this.contentsList.find((r) => {
        if (r.id === this.selectedShapeId) return true
      })
      item._x = item.shapeType === 'triangle' ? e.target.x() - (e.target.width() / 2) : e.target.x()
      item._y = item.shapeType === 'triangle' ? e.target.y() - (e.target.height() / 2) : e.target.y()
      item.x = e.target.x()
      item.y = e.target.y()
      // console.log(item)
      // this.selected
    },
    handleTransformEnd (e) {
      try {
        const item = this.contentsList.find((r) => {
          if (r.id === this.selectedShape.attrs.id) return r
        })

        const shapeConfig = e.target

        item.offsetX = Number(0)
        item.offsetY = Number(0)

        // item.x = Number(shapeConfig.getAbsolutePosition().x)
        // item.y = Number(shapeConfig.getAbsolutePosition().y)
        // item._x = Number(shapeConfig.getAbsolutePosition().x)
        // item._y = Number(shapeConfig.getAbsolutePosition().y)
        item.x = Number(shapeConfig.getPosition().x)
        item.y = Number(shapeConfig.getPosition().y)
        item._x = Number(shapeConfig.getPosition().x)
        item._y = Number(shapeConfig.getPosition().y)

        // console.log(shapeConfig.getAbsolutePosition())
        // console.log(shapeConfig.getPosition())

        item.rotation = Math.ceil(Number(shapeConfig.rotation()))
        item._width = Math.ceil(Number(shapeConfig.width() * shapeConfig.scaleX()))
        item._height = Math.ceil(Number(shapeConfig.height() * shapeConfig.scaleY()))

        if (item.shapeType === 'product' || item.shapeType === 'text') {
          item.fontSize = shapeConfig.fontSize()
          const width = item._width
          const textItem = this.selectedShape.measureSize(item.text)
          if (item.fit) {
            item.height = Number(shapeConfig.height() * shapeConfig.scaleY())
            shapeConfig.scaleY(1)
            if (width > textItem.width) {
              item.width = Number(shapeConfig.width() * shapeConfig.scaleX())
              shapeConfig.scaleX(1)
            } else {
              item.width = textItem.width
              item.scaleX = shapeConfig.scaleX()
            }
          } else {
            item.width = Number(shapeConfig.width() * shapeConfig.scaleX())
            item.height = Number(shapeConfig.height() * shapeConfig.scaleY())
            shapeConfig.scaleX(1)
            shapeConfig.scaleY(1)
          }
        } else {
          item.scaleX = Number(shapeConfig.scaleX())
          item.scaleY = Number(shapeConfig.scaleY())
        }
        // item.width = Number(shapeConfig.width() * shapeConfig.scaleX())
        // item.height = Number(shapeConfig.height() * shapeConfig.scaleY())

        // item._width = Math.ceil(Number(shapeConfig.width() * shapeConfig.scaleX()))
        // item._height = Math.ceil(Number(shapeConfig.height() * shapeConfig.scaleY()))

        // item.scaleX = Number(1)
        // item.scaleY = Number(1)
        // shapeConfig.scaleX(1)
        // shapeConfig.scaleY(1)
        // console.log(shapeConfig)
        // console.log(item)
      } catch (e) {
        console.debug(e)
      }
    },
    handleTriggerChange () {
      if (this.template.animation.selected === 'Self') {
        this.template.animation.item = {}
      }
    },
    increment () {
      this.scale = (this.scale / 100) * 125
      this.setScale()
    },
    getAnimationState () {
      if (this.animationStatus.start) {
        if (this.template.animation.actionEffect.selected === 'Bounce') return 'preview-img animation-bounce'
        else return 'preview-img animation'
      } else if (this.animationStatus.end) {
        return 'preview-img animation-end'
      } else {
        return 'preview-img'
      }
    },
    previewClick () {
      (this.$refs.transformer.getNode()).nodes([])
      this.animationStatus.end = false
      this.animationStatus.start = true
      // var stage = this.$refs.stage.getStage()

      // stage.children[0].children.forEach(function (item) {
      //   item.attrs.oldText = item.attrs.text
      //   item.attrs.text = ''
      // })

      if (Number(this.template.animation.start.width) === 0) this.template.animation.start.width = this.configKonva.width
      if (Number(this.template.animation.start.height) === 0) this.template.animation.start.height = this.configKonva.height
      if (Number(this.template.animation.end.width) === 0) this.template.animation.end.width = this.configKonva.width
      if (Number(this.template.animation.end.height) === 0) this.template.animation.end.height = this.configKonva.height

      const style = document.documentElement.style

      const ratioWidth = this.previewWidth / this.template.info.width
      const ratioHeight = this.previewHeight / this.template.info.height

      style.setProperty('--animation-start-width', Number(this.template.animation.start.width * ratioWidth) + 'px')
      style.setProperty('--animation-start-height', Number(Number(this.template.animation.start.height) * ratioHeight) + 'px')
      style.setProperty('--animation-end-width', Number(this.template.animation.end.width * ratioWidth) + 'px')
      style.setProperty('--animation-end-height', Number(Number(this.template.animation.end.height) * ratioHeight) + 'px')

      style.setProperty('--animation-duration', Number(this.template.animation.actionTime.selected.value) + 'ms')
      style.setProperty('--animation-start-x', Number(this.template.animation.start.x) + 'px')
      style.setProperty('--animation-start-y', Number(this.template.animation.start.y) + 'px')
      style.setProperty('--animation-start-o', Number(this.template.animation.start.opacity) / 100)
      style.setProperty('--animation-end-x', Number(this.template.animation.end.x) + 'px')
      style.setProperty('--animation-end-y', Number(this.template.animation.end.y) + 'px')
      style.setProperty('--animation-end-o', Number(this.template.animation.end.opacity) / 100)
      style.setProperty('--animation-name', 'previewAnimation')
      const actionEffect = this.template.animation.actionEffect.selected
      if (actionEffect === 'None') {
        style.setProperty('--animation-type', '')
      } else if (actionEffect === 'Ease Out') {
        style.setProperty('--animation-type', [0.2, 0.65, 0.355, 1.0])
      } else if (actionEffect === 'Ease In') {
        style.setProperty('--animation-type', [0.8, 0.005, 1, 1])
      } else if (actionEffect === 'Ease In-Out') {
        style.setProperty('--animation-type', [0.645, 0.045, 0.355, 1, 1, 1])
      } else if (actionEffect === 'Snap') {
        style.setProperty('--animation-type', [0.315, 0.405, 0.645, 1.145])
      } else if (actionEffect === 'Wind Up') {
        style.setProperty('--animation-type', [0.315, -0.245, 0.84, 0.7])
      } else if (actionEffect === 'Bounce') {
        const startX = Number(this.template.animation.start.x)
        const startY = Number(this.template.animation.start.y)
        const endX = Number(this.template.animation.end.x)
        const endY = Number(this.template.animation.end.y)
        style.setProperty('--animation-name', 'bounceAnimation')
        if (startX > endX) {
          style.setProperty('--animation-x-step1', (startX - (startX - endX) * 0.78) + 'px')
          style.setProperty('--animation-x-step2', (startX - (startX - endX) * 0.9) + 'px')
        } else {
          style.setProperty('--animation-x-step1', (startX + (endX - startX) * 0.78) + 'px')
          style.setProperty('--animation-x-step2', (startX + (endX - startX) * 0.9) + 'px')
        }
        if (startY > endY) {
          style.setProperty('--animation-y-step1', (startY - (startY - endY) * 0.78) + 'px')
          style.setProperty('--animation-y-step2', (startY - (startY - endY) * 0.9) + 'px')
        } else {
          style.setProperty('--animation-y-step1', (startY + (endY - startY) * 0.78) + 'px')
          style.setProperty('--animation-y-step2', (startY + (endY - startY) * 0.9) + 'px')
        }
      }

      setTimeout(() => {
        this.animationStatus.start = false
        this.animationStatus.end = true
        setTimeout(() => {
          this.animationStatus.end = false
        }, this.template.animation.actionTime.selected.value)
      }, this.template.animation.actionTime.selected.value + (this.template.animation.holdingTime * 1000))

      this.previewImage = this.$refs.stage.getStage().toDataURL()
    },
    animationPreviewSet () {
      (this.$refs.transformer.getNode()).nodes([])

      this.animationImage = this.$refs.stage.getStage().toDataURL()
    },

    async saveAnimation () {
      /* 저장 */
      if (this.template.info.opacity.value === 0) this.template.info.opacity.value = '0.0'
      this.template.animation.start.x = this.template.animation.start.x !== '' ? Number(this.template.animation.start.x) : 0
      this.template.animation.start.y = this.template.animation.start.y !== '' ? Number(this.template.animation.start.y) : 0
      this.template.animation.end.x = this.template.animation.end.x !== '' ? Number(this.template.animation.end.x) : 0
      this.template.animation.end.y = this.template.animation.end.y !== '' ? Number(this.template.animation.end.y) : 0

      if (Number(this.template.animation.start.width) === 0) this.template.animation.start.width = Number(this.template.info.width)
      if (Number(this.template.animation.start.height) === 0) this.template.animation.start.height = Number(this.template.info.height)
      if (Number(this.template.animation.end.width) === 0) this.template.animation.end.width = Number(this.template.info.width)
      if (Number(this.template.animation.end.height) === 0) this.template.animation.end.height = Number(this.template.info.height)

      this.template.info.width = Number(this.template.info.width)
      this.template.info.height = Number(this.template.info.height)

      this.template.animation.start.width = this.template.info.width
      this.template.animation.start.height = this.template.info.height
      this.template.animation.end.width = this.template.info.width
      this.template.animation.end.height = this.template.info.height

      if (this.template.info.title === '') {
        EventBus.$emit('messageAlert', this.$t('Title is not input'))
        return
      }
      (this.$refs.transformer.getNode()).nodes([])

      // const stage = this.$refs.stage.getStage()
      const templateThumbnail = this.$refs.stage.getStage().toDataURL()

      await this.contentsList.forEach((item, index) => {
        if (item.shapeType === 'product') {
          this.contentsList[index].oldText = item.text
          this.contentsList[index].text = ''
          if (String(item.fontFamily).indexOf('douglas') > -1) {
            item.height = item.fontSize + 4
          }
        }
      })

      const templateImage = this.$refs.stage.getStage().toDataURL()

      // var templateImage = this.$refs.stage.getStage().toDataURL()
      /*
        Text를 다시 넣어준다.
        Text 있는 이미지도 하나 얻ㄴ
      */
      await this.contentsList.forEach((item, index) => {
        // this.contentsList.splice(item._idx, 0, item)
        if (item.shapeType === 'product') {
          this.contentsList[index].text = item.oldText
          this.contentsList[index].oldText = ''
        }
      })

      // stage.children[0].children.forEach(function (item) {
      //   if (item.attrs.shapeType === 'text' && item.attrs.isProduct) {
      //     item.attrs.text = item.attrs.oldText
      //   }
      // })
      /*
        여기서 Template Data를 얻는다 이미지는 이 전 에 Base64로 변경해서 저장한다.
      */
      // console.log(templateImage === templateThumbnail)
      this.template.shapes = this.contentsList
      const templateData = JSON.stringify(this.template)
      const data = new FormData()

      data.append('idx_template', this.index ? this.index : window.sessionStorage.getItem('template_index'))
      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('title', this.template.info.title)
      data.append('template_thumbnail', templateThumbnail)
      data.append('template_img', templateImage)
      data.append('template_data', templateData)
      data.append('width_px', this.template.info.width)
      data.append('type', 'item')
      data.append('height_px', this.template.info.height)
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.isSaveClicked = true

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.putTemplateList.method,
          dicamoApi.requests.putTemplateList.url,
          data
        )
        .then(res => {
          if (res.data.result) {
            this.$router.push('/Lcd/Template/TemplateList')
            EventBus.$emit('messageAlert', this.$t('SUCCESS'))
          } else {
            EventBus.$emit('messageAlert', this.$t(res.data.message))
          }
          // this.cancelAddMedia()
          // this.$emit('fireResetMedia')
          // loader.hide()
        })
        .catch(error => {
          this.isSaveClicked = false
          console.debug(`Put error template item. error:, ${error}`)
        })
    },
    setScale () {
      var tmpScale = Math.round(this.scale * 100) / 100
      this.configKonva.height = this.template.info.height * tmpScale
      this.configKonva.width = this.template.info.width * tmpScale
      this.$refs.stage.getStage().setScale(
        {
          x: tmpScale,
          y: tmpScale
        }
      )
      this.$refs.canvas.style.width = Number(this.configKonva.width + 2) + 'px'
      this.$refs.canvas.style.height = Number(this.configKonva.height + 2) + 'px'
    },
    startDecrement () {
      // this.actionShape.startScale = (this.actionShape.startScale / 100) / 125
      this.actionShape.startScale = this.actionShape.startScale * 0.75
      if (Number(this.actionShape.startScale) < 0.5) {
        this.actionShape.startScale = 0.5
      }
    },
    startIncrement () {
      // this.actionShape.startScale = (this.actionShape.startScale / 100) * 125
      this.actionShape.startScale = this.actionShape.startScale * 1.25
      if (Number(this.actionShape.startScale) > 2.0) {
        this.actionShape.startScale = 2.0
      }
    },
    updateTransformer () {
      const transformerNode = this.$refs.transformer.getNode()
      const stage = transformerNode.getStage()

      const selectedNode = stage.findOne(node => {
        if (node.getType() === 'Shape') {
          if (node.attrs.id === this.selectedShape) return node
        }
      })

      if (selectedNode !== undefined) {
        this.selectedShape = selectedNode
        this.selectedShapeType = selectedNode.attrs.shapeType
      } else this.selectedShape = null

      if (selectedNode === transformerNode.node()) {
        return
      }

      if (selectedNode) {
        transformerNode.nodes([selectedNode])
      } else {
        transformerNode.nodes([])
      }
    }
  },
  computed: {
    selectedShapeId () {
      if (this.selectedShape === null) return 0
      else if (typeof (this.selectedShape) === 'string') return this.selectedShape
      else return this.selectedShape.attrs.id
    },
    selectProperty () {
      if (this.selectedShapeId === 0) return ''
      else {
        const node = this.selectedShape.attrs
        const text = this.selectedShape.textArr
        const item = {
          textArr: text,
          textWidth: node.textWidth,
          shapeType: node.shapeType,
          bind: node.shapeType === 'product' ? String(node.bind) : '',
          align: node.align,
          text: node.text,
          hitStrokeWidth: node.hitStrokeWidth,
          letterSpacing: node.letterSpacing,
          width: node._width,
          height: node._height,
          x: Math.ceil(node._x),
          y: Math.ceil(node._y),
          rotation: node.rotation,
          opacity: node.opacity,
          _opacity: node.opacity * 100,
          fill: node.fill,
          stroke: node.stroke,
          dash: [
            node.dash, node.space
          ],
          strokeWidth: node.strokeWidth,
          fontSize: node.fontSize,
          fontFamily: node.fontFamily,
          fontStyle: node.fontStyle,
          fit: node.fit,
          wrap: node.wrap,
          line: node.line ? node.line : 0
        }
        if (item.line === 0) {
        } else if (item.line < item.textArr.length) {
          item.textArr.splice(item.line)
        }
        return item
      }
    },
    previewSize () {
      const ratio = 216 / 1920
      if (this.templateSize.value === 23) {
        return {
          width: Number(this.template.info.width) * ratio + 'px',
          height: Number(this.template.info.height) * ratio - 3 + 'px'
        }
      } else {
        return {
          width: Number(this.template.info.width) * ratio + 'px',
          height: Number(this.template.info.height) * ratio - 3 + 'px'
        }
      }
    },
    ratio () {
      if (this.templateSize.value === 23) {
        return {
          width: 218,
          height: 18
        }
      } else if (this.templateSize.value === 29) {
        return {
          width: 218,
          height: 61
        }
      } else {
        return {
          width: 218,
          height: 122
        }
      }
    },
    previewWidth () {
      if (this.configKonva.width > this.configKonva.height * 2) return 210
      else {
        const ratio = 130 / this.configKonva.height

        return this.configKonva.width * ratio
      }
    },
    previewHeight () {
      if (this.configKonva.height * 2 > this.configKonva.width) return 130
      else {
        const ratio = 210 / this.configKonva.width

        return this.configKonva.height * ratio
      }
    },
    parseRawData () {
      if (this.templateData.raw_data) {
        return JSON.parse(this.templateData.raw_data)
      } else {
        return ''
      }
    }
  },
  watch: {
    startDialog (val) {
      if (val === true) {
        this.animationPreviewSet()
      } else {
        this.animationImage = ''
      }
    },
    endingDialog (val) {
      if (val === true) {
        this.animationPreviewSet()
      } else {
        this.animationImage = ''
      }
    }
  }
}
</script>

<style scoped>
::v-deep .template-layout-sider .card-animation .ratio-preview {
  display: flex;
  align-items: baseline !important;
  justify-content: normal !important;
  height: 130px;
}
::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #031E37 !important;
}
::v-deep .preview-img {

  background-repeat: no-repeat;
  /* background-position: center; */
}
::v-deep .font-select {
  text-indent: 30px;
}
::v-deep .font-center input {
  text-align: center;
}
.animation-preview {
  text-align: center;
  text-align: -webkit-center;
  /* width: 100%;
  height: 100%; */
}
.selectedRow {
  color: white !important;
  background-color: #152a3f !important;
  /* font-weight: bold; */
}
::v-deep .ps__rail-x {
  opacity: 0.6 !important;
}
::v-deep .ps__rail-y {
  opacity: 0.6 !important;
}
::v-deep .animation {
  position: relative;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  animation-name: previewAnimation;
  animation-delay: 0s;
  animation-duration: var(--animation-duration);
  animation-timing-function: cubic-bezier(var(--animation-type));
  animation-fill-mode: forwards;
  /* animation-duration: 10s; */
}
::v-deep .animation-end {
  position: relative;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  animation-name: previewAnimationEnd;
  animation-delay: 0s;
  animation-duration: var(--animation-duration);
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  /* animation-duration: 10s; */
}

::v-deep .animation-bounce {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  animation-name: bounce-top;
  animation-delay: 0s;
  animation-duration: var(--animation-duration);
  /* animation-timing-function: cubic-bezier(var(--animation-type)); */
  /* animation-timing-function: cubic-bezier(0.8, 0.85, 0.9, 1.0); */
  animation-fill-mode: forwards;
}
/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #c0bdbd;
  border-radius: 5px;
}
/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::v-deep .v-tooltip__content:after {
    content: "";
    display: block;
}

@keyframes previewAnimation {
  0% {
    left: var(--animation-start-x);
    top: var(--animation-start-y);
    width: var(--animation-start-width);
    height: var(--animation-start-height);
    opacity: var(--animation-start-o);
  }
  100% {
    left: var(--animation-end-x);
    top: var(--animation-end-y);
    width: var(--animation-end-width);
    height: var(--animation-end-height);
    opacity: var(--animation-end-o);
  }
}

@keyframes previewAnimationEnd {
  0% {
    left: var(--animation-end-x);
    top: var(--animation-end-y);
    width: var(--animation-end-width);
    height: var(--animation-end-height);
  }
  100% {
    left: var(--animation-start-x);
    top: var(--animation-start-y);
    width: var(--animation-start-width);
    height: var(--animation-start-height);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-11-11 12:18:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
@keyframes bounce-top {
  0% {
    transform: translate(var(--animation-start-x), var(--animation-start-y));
    animation-timing-function: ease-in;
    opacity: var(--animation-start-o);
  }
  /* 24% {
  } */
  40% {
    transform: translate(var(--animation-x-step1), var(--animation-y-step1));
    animation-timing-function: ease-in;
  }
  65% {
    transform: translate(var(--animation-x-step2), var(--animation-y-step2));
    animation-timing-function: ease-in;
  }
  /* 82% {
    transform: translate(var(--animation-x-step3), var(--animation-y-step3));
    animation-timing-function: ease-in;
  } */
  /* 93% {
    transform: translate(var(--animation-end-x), var(--animation-end-y));
    animation-timing-function: ease-in;
  } */
  25%,
  55%,
  75%,
  100% {
    transform: translate(var(--animation-end-x), var(--animation-end-y));
    animation-timing-function: ease-out;
    opacity: var(--animation-end-o);
  }
}
</style>
